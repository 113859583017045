import ArrayUtil from "ArrayUtil"
import BlockType from "../../../../../../Constants/BlockType";
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import FormatterUtil from "FormatterUtil";
import GraphType from "GraphType";
import { LightDarkColorConstants } from "../../../../../../Constants/LightDarkColorConstants.js";
import LocalizationStore from "LocalizationStore";
import moment from "moment";
import React from 'react';
import ReduxState from "../../../../../../Redux/store";
import StockMarketUtil from "../../../../../../Utils/StockMarketUtil";
import StringUtil from "../../../../../../Utils/StringUtil";
import ThemeHelper from "ThemeHelper";
import { AnnouncementConstants, CorporateEventsConst } from "../../../../../../Constants/PriceChartConstants";
import { AnnouncementTranslateHelper, CorporateTranslateHelper, DividendTranslateHelper, IndicatorCommonTranslateHelper, IndicatorHeaderTranslateHelper, IndicatorLabelTranslateHelper, IndicatorLineTranslateHelper, MajorArticlesTranslateHelper, OneilRatingIndicatorTranslateHelper, TrackpricePeriodicityTranslateHelper, TrackPriceTranslateHelper } from "../../../../../../Utils/TranslateHelper";
import { each, map } from "underscore";

class TrackPriceHelper {
  constructor() {
    this.posDTColor = '';
    this.negDTColor = ''
  }
  getPriceChartTrackPriceNodeData(nodeIndex, trackpriceReqArgs) {
    const reduxState = ReduxState.getState();
    const _state = reduxState.DatagraphReducers.PriceChartReducer;
    this.posDTColor = reduxState.appColor.posDTColor;
    this.negDTColor = reduxState.appColor.negDTColor;
    const DatagraphReducer = reduxState.DatagraphReducers.DataGraphReducer
    this.periodicity = DatagraphReducer.periodicity;
    this.majorPeriodicity = DatagraphReducer.majorPeriodicity;
    this.viewsSettings = DatagraphReducer.viewsSettings;

    if (!_state.HiLowPoints.allPoints) {
      return trackPriceNode;
    }
    if (nodeIndex >= _state.HiLowPoints.allPoints.length) {
      return trackPriceNode;
    }
    if (!_state.HiLowPoints.allPoints[nodeIndex]) {
      return trackPriceNode;
    }
    const trackPriceNode = {};
    trackPriceNode.pointers = [];
    trackPriceNode.corporateEvent = [];
    trackPriceNode.ideasEvent = [];
    trackPriceNode.maValues = [];
    trackPriceNode.externalDataEvent = [];
    trackPriceNode.indicatorNode = { values: [] };
    const nodeData = _state.HiLowPoints.allPoints[nodeIndex].graphData;
    const prevNode = _state.HiLowPoints.allPoints[nodeIndex + 1] ? _state.HiLowPoints.allPoints[nodeIndex + 1].graphData : nodeData;
    let lastNode = true;
    if (_state.HiLowPoints.allPoints[nodeIndex + 1]) {
      lastNode = false;
    }
    let format = nodeData.Close < 1 ? 3 : 2;
    const prevPrice = lastNode ? 0 : prevNode.Close;
    let percentChange = prevPrice > 0 ? (nodeData.Close - prevPrice) / prevPrice : 0;
    percentChange *= 100;
    let sign = percentChange > 0 ? "+" : (percentChange < 0 ? "-" : "");
    trackPriceNode.pctChange = `${sign} ${ExtremeDataValue.chgs(percentChange, false)} %`;
    const amountChanged = nodeData.Close - prevPrice;
    sign = amountChanged > 0 ? "+" : "";
    if (Math.abs(amountChanged) >= 1) {
      trackPriceNode.amountChanged = sign + ExtremeDataValue.showPrice(amountChanged);
    }
    else {
      trackPriceNode.amountChanged = sign + amountChanged.toFixed(3);
    }
    if (nodeData._volume === -1) {
      trackPriceNode.volume = TrackPriceTranslateHelper.HOLIDAY;
      trackPriceNode.fullVolume = TrackPriceTranslateHelper.HOLIDAY;
    }
    else if (nodeData._volume === -2) {
      trackPriceNode.volume = TrackPriceTranslateHelper.MARKET_CLOSE;
      trackPriceNode.fullVolume = TrackPriceTranslateHelper.MARKET_CLOSE;
    }
    else if (nodeData._volume === -3 || nodeData._volume === 0) {
      trackPriceNode.volume = TrackPriceTranslateHelper.NO_TRADE;
      trackPriceNode.fullVolume = TrackPriceTranslateHelper.NO_TRADE;
    }
    else {
      if (nodeData._volume < 100000 && nodeData._volume >= 10000) {
        trackPriceNode.volume = ExtremeDataValue.abbreviateValue(nodeData._volume, 3);
      }
      else {
        if (nodeData._volume < 1000000 && nodeData._volume >= 100000) {
          trackPriceNode.volume = ExtremeDataValue.abbreviateValue(nodeData._volume, 2);
        }
        else {
          if (nodeData._volume < 10000000 && nodeData._volume >= 1000000) {
            trackPriceNode.volume = ExtremeDataValue.abbreviateValue(nodeData._volume, 1);
          }
          else {
            trackPriceNode.volume = ExtremeDataValue.abbreviateValue(nodeData._volume, 0);
          }
        }
      }
      trackPriceNode.fullVolume = ExtremeDataValue.getFormattedNumber(nodeData._volume);
    }

    // Moving Average Line
      const _maLineState = reduxState.DatagraphReducers.MAReducer;
      trackPriceNode.pointers.push({ Key: "price" });
      if (_maLineState.maLineData.length > 0) {
        const mAverageList = _maLineState.maSettings;
        for (let i = 0; i < _maLineState.maLineData.length; i++) {
          if (_maLineState.maLineData[i] &&
            _maLineState.maLineData[i].length > 0 &&
            _maLineState.maLineData[i].length > nodeIndex &&
            _maLineState.maLineData[i][nodeIndex] &&
            _maLineState.maLineData[i][nodeIndex].yValue &&
            mAverageList[i].IsVisible) {
            const maLineValue = _maLineState.maLineData[i][nodeIndex].yValue;
            format = maLineValue < 1 ? 3 : 2;
            const changeInfo = ((maLineValue - nodeData.Close) / nodeData.Close) * 100;
            const maLineChange = ((nodeData.Close - maLineValue) / maLineValue) * 100;
            sign = maLineChange > 0 ? "+" : "";
            const maValue = {
              maPrice: ExtremeDataValue.showPrice(maLineValue),
              maPercChange: `${sign} ${maLineChange.toFixed(format)} %`,
              maPrChg: `(${sign} ${Math.round(maLineChange)} %)`,
              maColor: ThemeHelper.getThemedBrush(_maLineState.maSettings[i].lineColor),
              maPrChgColor: maLineChange > 0 ? this.posDTColor : this.negDTColor,
            };
            trackPriceNode.maValues[i] = maValue;
            trackPriceNode.pointers.push({
              Key: `maLine ${i}`,
              dispTextL: trackPriceNode.maValues[i].maPrice,
              dispTextR: `(${(changeInfo > 0 ? "+" : "")} ${Math.round(changeInfo)}%)`,
              yAxis: _state.scale.ComputeY(maLineValue),
              Color: _maLineState.maSettings[i].lineColor
            });
          }
        }
      }
      // .......Moving  Avgerage Line
      trackPriceNode.pointers.push({
        Key: "closePrice",
        dispTextL: ExtremeDataValue.showPrice(nodeData.Close),
        dispTextR: "",
        yAxis: _state.scale.ComputeY(nodeData.Close),
        Color: "#FFFFFF"
      });
      if (_state.HiLowPoints.allPoints[nodeIndex].corpIndex && reduxState.DatagraphReducers.CorpEventsReducer.isCorpEventVisible) {
        const len = _state.HiLowPoints.allPoints[nodeIndex].corpIndex.length;
        for (let each = 0; each < len; each++) {
          trackPriceNode.corporateEvent.push(reduxState.DatagraphReducers.CorpEventsReducer.corpeventsData[_state.HiLowPoints.allPoints[nodeIndex].corpIndex[each]]);
        }
      }
      if (_state.HiLowPoints.allPoints[nodeIndex].IdeasTr) {
        const len = _state.HiLowPoints.allPoints[nodeIndex].IdeasTr.length;
        const oneilReducer = reduxState.RelatedInfoPanelReducers.RIPanelOneilReducers
        for (let each = 0; each < len; each++) {
          trackPriceNode.ideasEvent.push(oneilReducer.ideasDisplay[_state.HiLowPoints.allPoints[nodeIndex].IdeasTr[each]]);
        }
      }
      const externalStates = reduxState.externalDataUploader.externalDataUploadSymbolReducer.eventSeriesData_AllPoints
      if (externalStates) {
        each(externalStates, (data) => {
          if (data.length > nodeIndex && data[nodeIndex] && (!StringUtil.isEmpty(data[nodeIndex].category) || data[nodeIndex].length > 1)) {
            if (data[nodeIndex].length > 0) {
              each(data[nodeIndex], (item) => {
                trackPriceNode.externalDataEvent.push({ header: item.category, value: '' });
              })
            }
            else if (data[nodeIndex].count > 1) {
              trackPriceNode.externalDataEvent.push({ header: "count", value: data[nodeIndex].count });
            }
            else {
              trackPriceNode.externalDataEvent.push({ header: data[nodeIndex].category, value: '' });
            }
          }
        })
      }
      trackPriceNode.high = ExtremeDataValue.showPrice(nodeData.High);
      trackPriceNode.low = ExtremeDataValue.showPrice(nodeData.Low);
    if (trackpriceReqArgs.chartType === BlockType.ExternalData) {
      const state = reduxState.externalDataUploader.externalDataUploadSymbolReducer;
      this.getExternalTrackPriceNodeData(nodeIndex, _state, state, trackPriceNode, trackpriceReqArgs)
    }
    else if (trackpriceReqArgs.chartType === BlockType.KPI) {
      const state = reduxState.kpiMenu.KpiModuleReducer;
      this.getKPITrackPriceNodeData(nodeData, _state, state, trackPriceNode, trackpriceReqArgs)
    }
    else if (trackpriceReqArgs.chartType === BlockType.EPSR) {
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      trackPriceNode.pointers.push({ Key: "tech" });
      trackPriceNode.indicatorNode.header = IndicatorLabelTranslateHelper[BlockType.EPSR];
      indicatorState.forEach((item) => {
        if (item.DataSource[nodeIndex]) {
          trackPriceNode.indicatorNode.values.push({
            key: item.key,
            value: item.DataSource[nodeIndex].yValue.toFixed(2),
            valueColor: item.LineColor,
            header: IndicatorLineTranslateHelper[item.key]
          });
        }
      });
    }
    else if (trackpriceReqArgs.chartType === BlockType.PTOE || trackpriceReqArgs.chartType === BlockType.PTOS) {
      this.get_PE_PS_NodeDataByGraphDate(nodeData.Date, reduxState.DatagraphReducers.IndicatorReducer, trackPriceNode, trackpriceReqArgs);
    }
    else if (trackpriceReqArgs.chartType === BlockType.RSI) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      const indicatorSetting = reduxState.DatagraphReducers.IndicatorReducer
      trackPriceNode.indicatorNode.header = `${IndicatorLabelTranslateHelper[BlockType.RSI]} (${indicatorSetting.SettingDict[trackpriceReqArgs.chartType].Length} ${TrackpricePeriodicityTranslateHelper[this.periodicity]})`;
      if (indicatorState[2].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[2].key,
          value: Math.floor(indicatorState[2].DataSource[nodeIndex].yValue),
          valueColor: indicatorState[2].LineColor,
          header: IndicatorLabelTranslateHelper[BlockType.RSI]
        });
      }
    }
    else if (trackpriceReqArgs.chartType === BlockType.Stochastics || trackpriceReqArgs.chartType === BlockType.WonStochastics) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      const indicatorSetting = reduxState.DatagraphReducers.IndicatorReducer
      trackPriceNode.indicatorNode.header = `${IndicatorLabelTranslateHelper[trackpriceReqArgs.chartType]} (${indicatorSetting.SettingDict[trackpriceReqArgs.chartType].Length} ${TrackpricePeriodicityTranslateHelper[this.periodicity]})`;
      if (indicatorState[2].DataSource) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[2].key,
          value: (indicatorState[2].DataSource[nodeIndex]?.yValue || 0).toFixed(0),
          valueColor: indicatorState[2].LineColor,
          header: IndicatorLineTranslateHelper.FAST
        });
      }
      if (indicatorState[3].DataSource) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[3].key,
          value: (indicatorState[3].DataSource[nodeIndex]?.yValue || 0).toFixed(0),
          valueColor: indicatorState[3].LineColor,
          header: IndicatorLineTranslateHelper.SLOW
        });
      }
    }
    else if (trackpriceReqArgs.chartType === BlockType.Extended) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      trackPriceNode.indicatorNode.header = `${IndicatorLabelTranslateHelper[BlockType.Extended]}`;
      if (indicatorState[0] && indicatorState[0].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[0].key,
          value: indicatorState[0].DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: indicatorState[0].LineColor,
          header: IndicatorLineTranslateHelper.SHORT
        });
      }
      if (indicatorState[1] && indicatorState[1].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[1].key,
          value: indicatorState[1].DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: indicatorState[1].LineColor,
          header: IndicatorLineTranslateHelper.LONG
        });
      }
      if (indicatorState[2] && indicatorState[2].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[2].key,
          value: indicatorState[2].DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: indicatorState[2].LineColor,
          header: IndicatorLineTranslateHelper.SD_UP
        });
      }
      if (indicatorState[3] && indicatorState[3].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[3].key,
          value: indicatorState[3].DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: indicatorState[3].LineColor,
          header: IndicatorLineTranslateHelper.SD_DN
        });
      }
    }
    else if (trackpriceReqArgs.chartType === BlockType.MACD) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      const indicatorSetting = reduxState.DatagraphReducers.IndicatorReducer
      trackPriceNode.indicatorNode.header = `${IndicatorLabelTranslateHelper[BlockType.MACD]} (${indicatorSetting.SettingDict[trackpriceReqArgs.chartType].FastLength}, ${indicatorSetting.SettingDict[trackpriceReqArgs.chartType].SlowLength}, ${indicatorSetting.SettingDict[trackpriceReqArgs.chartType].EMA})`;
      if (indicatorState[1].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[1].key,
          value: indicatorState[1].DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: indicatorState[1].LineColor,
          header: IndicatorLabelTranslateHelper[BlockType.MACD]
        });
      }
      if (indicatorState[2].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[2].key,
          value: indicatorState[2].DataSource[nodeIndex].yValue.toFixed(1),
          valueColor: indicatorState[2].LineColor,
          header: IndicatorLineTranslateHelper.EMA
        });
      }
      if (indicatorState[0].DataSource.allPoints[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[0].key,
          value: indicatorState[0].DataSource.allPoints[nodeIndex].yValue.toFixed(1),
          valueColor: indicatorState[0].DataSource.allPoints[nodeIndex].yValue >= 0 ? this.posDTColor : this.negDTColor,
          header: IndicatorLineTranslateHelper.HISTOGRAM
        });
      }

    }
    else if (trackpriceReqArgs.chartType === BlockType.TechIndicator) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      trackPriceNode.indicatorNode.header = IndicatorHeaderTranslateHelper[trackpriceReqArgs.chartType];
      indicatorState.forEach((item) => {
        if (item.DataSource[nodeIndex]) {
          trackPriceNode.indicatorNode.values.push({
            key: item.key,
            value: item.DataSource[nodeIndex].yValue.toFixed(0),
            valueColor: item.LineColor,
            header: OneilRatingIndicatorTranslateHelper[item.key]
          });
        }
      });
    }
    else if (trackpriceReqArgs.chartType === BlockType.YTD || trackpriceReqArgs.chartType === BlockType.YTD1 || trackpriceReqArgs.chartType === BlockType.YTD3 || trackpriceReqArgs.chartType === BlockType.YTD5 || trackpriceReqArgs.chartType === BlockType.YTD10) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      trackPriceNode.indicatorNode.header = IndicatorLineTranslateHelper[trackpriceReqArgs.chartType];
      indicatorState.forEach((item) => {
        item.DataSource.allPoints.forEach((nodeValue)=>{
            if(nodeValue.Date.getTime() === nodeData.Date.getTime()){
              trackPriceNode.indicatorNode.values.push({
                key: item.key,
                value: `${nodeValue.value > 0 ? `+${nodeValue.value.toFixed(1)}` : nodeValue.value.toFixed(1)} %`,
                itemColor: nodeValue.value >= 0 ? this.posDTColor : this.negDTColor,
                header: `${IndicatorLineTranslateHelper[trackpriceReqArgs.chartType]} %`
              });
            }
        } );
      });
      if(trackPriceNode.indicatorNode.values.length === 0){
        trackPriceNode.indicatorNode.values.push({
          key: 'YTD',
          value: ``,
          header: `${IndicatorLineTranslateHelper[trackpriceReqArgs.chartType]} %`
        });
      }
    }
    else if (trackpriceReqArgs.chartType === BlockType.AccDist) {
      trackPriceNode.pointers.push({ Key: "tech" });
      const indicatorState = reduxState.DatagraphReducers.IndicatorReducer.indicatorVisuals[trackpriceReqArgs.chartType];
      trackPriceNode.indicatorNode.header = IndicatorLineTranslateHelper[BlockType.AccDist];
      if (indicatorState[0].DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: indicatorState[0].key,
          value: `${this.ACDCValue(indicatorState[0].DataSource[nodeIndex].yValue)} ${indicatorState[0].DataSource[nodeIndex].yValue.toFixed(0)}`,
          valueColor: indicatorState[0].LineColor,
          header: IndicatorLineTranslateHelper['RATING']
        });
      }
    }
    else if (trackpriceReqArgs.chartType === BlockType.Insider) {
      this.getInsiderTrackPriceNodeData(nodeIndex, reduxState.DatagraphReducers.insiderChartReducer, trackPriceNode)
    }
    this.getVolumeTrackPriceNodeData(nodeIndex, reduxState.DatagraphReducers.volumeChartReducer, trackPriceNode, trackpriceReqArgs, reduxState.DatagraphReducers.DataGraphReducer.pricePanelData, _state.isHistoric, nodeData);
    trackPriceNode.amountChangeColor = amountChanged >= 0 ? this.posDTColor : this.negDTColor;
    trackPriceNode.date = DateHelper.getDateString(nodeData.Date);
    if (this.majorPeriodicity === GraphType.Intraday) {
      trackPriceNode.time = DateHelper.getTPTimeString(nodeData.Date);
    }
    trackPriceNode.close = ExtremeDataValue.showPrice(nodeData.Close);
    trackPriceNode.node = _state.HiLowPoints.allPoints[nodeIndex];
    trackPriceNode._volume = nodeData._volume;
    trackPriceNode.nodeIndex = nodeIndex;

    return trackPriceNode;
  }

  ACDCValue(accumulationDistribution) {
    let accumulationDistributionLetter = " ";
    if (accumulationDistribution > 54) {
      accumulationDistributionLetter = "(A+)";
    }
    else if (accumulationDistribution > 44) {
      accumulationDistributionLetter = "(A)";
    }
    else if (accumulationDistribution > 34) {
      accumulationDistributionLetter = "(A-)";
    }
    else if (accumulationDistribution > 24) {
      accumulationDistributionLetter = "(B+)";
    }
    else if (accumulationDistribution > 14) {
      accumulationDistributionLetter = "(B)";
    }
    else if (accumulationDistribution > 4) {
      accumulationDistributionLetter = "(B-)";
    }
    else if (accumulationDistribution > -1) {
      accumulationDistributionLetter = "(C+)";
    }
    else if (accumulationDistribution > -6) {
      accumulationDistributionLetter = "(C)";
    }
    else if (accumulationDistribution > -11) {
      accumulationDistributionLetter = "(C-)";
    }
    else if (accumulationDistribution > -16) {
      accumulationDistributionLetter = "(D+)";
    }
    else if (accumulationDistribution > -21) {
      accumulationDistributionLetter = "(D)";
    }
    else if (accumulationDistribution > -31) {
      accumulationDistributionLetter = "(D-)";
    }
    else {
      accumulationDistributionLetter = "(E)";
    }

    return accumulationDistributionLetter;
  }

  //Start PEPS Indicator Helper
  get_PE_PS_NodeDataByGraphDate(nodeDate, state, trackPriceNode, trackpriceReqArgs) {
    const graphData = state.GraphData[trackpriceReqArgs.chartType];
    const symbol = state.indicatorVisuals[trackpriceReqArgs.chartType][0].key;
    const color = state.indicatorVisuals[trackpriceReqArgs.chartType][0].LineColor;
    const indexColor = state.indicatorVisuals[trackpriceReqArgs.chartType][1].LineColor;

    trackPriceNode.indicatorNode.header = state.indicatorLabels[trackpriceReqArgs.chartType].Label;
    if (graphData) {
      let indIndex = graphData.StockResult.findIndex((t) => t.Date.getTime() === nodeDate.getTime());
      if (indIndex > -1) {
        if (graphData.StockResult[indIndex].Close < 0) {
          return trackPriceNode;
        }
        if (graphData.StockResult[indIndex].Close !== 0) {
          trackPriceNode.indicatorNode.values.push({
            key: symbol,
            value: graphData.StockResult[indIndex].Close.toFixed(1),
            valueColor: color,
            header: symbol
          });
        } else {
          indIndex = graphData.ConsensusResult.findIndex((t) => t.Date.getTime() === nodeDate.getTime());
          if (indIndex > -1 && graphData.ConsensusResult[indIndex].Close !== 0) {
            trackPriceNode.indicatorNode.values.push({
              key: symbol,
              value: graphData.ConsensusResult[indIndex].Close.toFixed(1),
              valueColor: color,
              header: symbol
            });
          }
        }
      } else {
        indIndex = graphData.ConsensusResult.findIndex((t) => t.Date.getTime() === nodeDate.getTime());
        if (indIndex > -1 && graphData.ConsensusResult[indIndex].Close !== 0) {
          trackPriceNode.indicatorNode.values.push({
            key: symbol,
            value: graphData.ConsensusResult[indIndex].Close.toFixed(1),
            valueColor: color,
            header: symbol
          });
        }
      }
      indIndex = graphData.IndexResult.findIndex((t) => t.Date.getTime() === nodeDate.getTime());
      if (state.SettingDict[trackpriceReqArgs.chartType][`${trackpriceReqArgs.chartType}IndexLine`] && indIndex > -1 && graphData.IndexResult[indIndex].Close !== 0) {
        trackPriceNode.indicatorNode.values.push({
          key: "Index",
          value: graphData.IndexResult[indIndex] ? graphData.IndexResult[indIndex].Close.toFixed(1) : '',
          valueColor: indexColor,
          header: IndicatorCommonTranslateHelper.SymbolType_INDEX
        });
      }
    }
    trackPriceNode.pointers.push({ Key: "tech" });
    return trackPriceNode;
  }
  //End PEPS Indicator Helper
  //Start KPI Indicator Helper
  getKPITrackPriceNodeData(nodeData, pricechartStates, state, trackPriceNode, trackpriceReqArgs) {
    const dataPoints = state.KPIsDataPoints[trackpriceReqArgs.kpiId];
    let obj = '';
    if (this.majorPeriodicity === GraphType.Annual) {
      obj = dataPoints.find((item) => this.getDate(moment(`12-31-${item.fiscalYear}`, "MM-DD-YYYY 00:00:00"), this.majorPeriodicity) === DateHelper.getDateString(nodeData.Date))
    }
    else {
      obj = dataPoints.find((item) => this.getDate(StringUtil.getLongToMoment(item.endDate), this.majorPeriodicity) === DateHelper.getDateString(nodeData.Date))
    }
    const reportedValue = obj ? obj.reportedValue : null;
    const color = state.kpiSettingsDict[trackpriceReqArgs.kpiId].KpiColor[0].color;
    trackPriceNode.indicatorNode.header = trackpriceReqArgs.header
    if (reportedValue) {
      trackPriceNode.indicatorNode.values.push({ value: ExtremeDataValue.abbreviateKpiValueFormat(reportedValue, true), valueColor: ThemeHelper.getThemedBrush(color), header: "KPI" })
    }
  }

  getDate(indate) {
    let day = indate.day();
    let date = indate;
    if (this.majorPeriodicity === GraphType.Weekly) {
      if (day !== 5) {
        day = day < 5 ? (5 - day) : (5 - day + 7);
        date = moment(date, "DD-MM-YYYY").add(day, 'days');
      }
    }
    else if (this.majorPeriodicity === GraphType.Monthly) {
      date = moment(date).endOf('month');
      day = date.day();
      if (day === 0) {
        date = date.subtract(2, "days");
      }
      else if (day === 6) {
        date = date.subtract(1, "days");
      }
    }
    else {
      if (day === 0) {
        date = moment(date, "DD-MM-YYYY").subtract(2, 'days');
      }
      else if (day === 6) {
        date = moment(date, "DD-MM-YYYY").subtract(1, 'days');
      }
    }
    date = moment(date).format('M/D/YYYY');
    return date;
  }
  //End KPI Indicator Helper

  //Start External Indicator Helper
  findStockIndex(date, allPoints) {
    let index = 0;
    const lng = allPoints.length;
    if (allPoints[lng - 1] && date.getTime() < allPoints[lng - 1].Date.getTime()) {
      return -1;
    }
    for (; index < lng; index++) {
      if (date.getDate() === allPoints[index].Date.getDate() &&
        date.getFullYear() === allPoints[index].Date.getFullYear() &&
        date.getMonth() === allPoints[index].Date.getMonth()) {
        break;
      }
    }
    return index < lng ? index : -1;
  }

  getRollUpEndDate(endDate) {
    switch (this.periodicity) {
      case GraphType.Daily:
        return endDate;
      case GraphType.Weekly:
        return moment(moment(endDate).endOf("week").format('MM-DD-YYYY'), 'MM-DD-YYYY', true).subtract(1, 'days').toDate();
      case GraphType.Monthly:
        return StockMarketUtil.GetMEndDate(endDate);
      case GraphType.Quarterly:
        return StockMarketUtil.GetQEndDate(endDate);
      case GraphType.Annual:
        return StockMarketUtil.GetAEndDate(endDate);
      default:
        return endDate;
    }
  }

  getExternalTrackPriceNodeData(nodeIndex, pricechartStates, state, trackPriceNode, trackpriceReqArgs) {
    const data = state.timeSeriesData_indicatorPane[trackpriceReqArgs.listId];
    const dataPoints = state.timeSeriesData_indicatorPane_AllPoints[trackpriceReqArgs.listId];
    const children = trackpriceReqArgs.menu.children;
    const HiLowPoints = pricechartStates.HiLowPoints;
    if (!data) {
      return;
    }
    trackPriceNode.indicatorNode.header = trackpriceReqArgs.header
    let index = nodeIndex > -1 && HiLowPoints.allPoints[nodeIndex] ? this.findStockIndex(HiLowPoints.allPoints[nodeIndex].Date, dataPoints) : -1;
    if (index === -1 && nodeIndex > -1 && HiLowPoints.allPoints[nodeIndex]) {
      const rollUpEndDate = this.getRollUpEndDate(HiLowPoints.allPoints[nodeIndex].Date, this.periodicity);
      index = this.findStockIndex(rollUpEndDate, dataPoints);
    }
    const nodeDate = index > -1 ? dataPoints[index].Date : null;

    if (nodeDate) {
      map(children, (item, key) => {
        if (item && item.IsVisible && nodeDate) {
          map(data[key], (value) => {
            if (value.Date === nodeDate) {
              trackPriceNode.indicatorNode.values.push({ value: value.yValue.toFixed(2), valueColor: ThemeHelper.getThemedBrush(item.Color), header: item.Name });
            }
          })
        }
      });
    }

    trackPriceNode.pointers.push({ Key: "tech" });
    return trackPriceNode;
  }
  //End External Indicator Helper

  //Start EPSR Indicator Helper
  getEPSRTrackPriceNodeData(nodeIndex, state, trackPriceNode, trackpriceReqArgs) {
    const indicatorState = state.indicatorVisuals[trackpriceReqArgs.chartType];
    trackPriceNode.pointers.push({ Key: "tech" });
    trackPriceNode.indicatorNode.header = IndicatorLabelTranslateHelper[BlockType.EPSR];
    indicatorState.forEach((item) => {
      if (item.DataSource[nodeIndex]) {
        trackPriceNode.indicatorNode.values.push({
          key: item.key,
          value: item.DataSource[nodeIndex].yValue.toFixed(2),
          valueColor: item.LineColor,
          header: IndicatorLineTranslateHelper[item.key]
        });
      }
    });
    return trackPriceNode;
  }
  //End EPSR Indicator Helper

  //Start Volume Helper
  getVolumeTrackPriceNodeData(nodeIndex, state, trackPriceNode, trackpriceReqArgs, pricePanelData, isHistoric, nodeData) {
    if (trackpriceReqArgs.chartType === BlockType.Volume) {
      trackPriceNode.pointers.push({ Key: "volume" });
    }
    if (!state || !state.volMALineData) {
      return trackPriceNode;
    }
    if (nodeIndex >= state.volMALineData.length) {
      return trackPriceNode;
    }
    let pctChange = state.volumePercentChange;
    let percentChange = parseInt(pctChange);
    if(state.OpenInterestSettings?.IsVisible){
      trackPriceNode.OpenInterest = nodeData.OpenInterest
    }
    if (nodeIndex === 0 && state.volMALineData[nodeIndex + 1]) {
      const maValue = Math.round(state.volMALineData[nodeIndex + 1].maValue);
      trackPriceNode.avgVolume = isHistoric ? FormatterUtil.formatNumber(pricePanelData.volumePriceData.avgVolOfLastNode) : FormatterUtil.formatNumber(maValue);
      const sign = pctChange >= 0 ? "+" : "";
      pctChange = `${sign}${pctChange.toFixed(0)}%`;
      trackPriceNode.volumePercentChange = pctChange;
      trackPriceNode.volumeChangeColor = percentChange >= 0 ? this.posDTColor : this.negDTColor;
    }
    if (state.volMALineData[nodeIndex]) {
      const volData = state.volMALineData[nodeIndex].Volume;
      const maValue = Math.round(state.volMALineData[nodeIndex].maValue);
      percentChange = volData !== undefined && volData > 0 && maValue > 0 ? (volData - maValue) / maValue : 0;
      percentChange *= 100;
      const sign = percentChange >= 0 ? "+" : "";
      pctChange = `${sign}${percentChange.toFixed(0)}%`;
      trackPriceNode.avgVolume = FormatterUtil.formatNumber(maValue);
      if (nodeIndex > 0) {
        trackPriceNode.volumePercentChange = pctChange;
        trackPriceNode.volumeChangeColor = percentChange >= 0 ? this.posDTColor : this.negDTColor;
      }
    }
  }
  //End Volume Helper

  //Start Insider Helper
  getInsiderTrackPriceNodeData(nodeIndex, state, trackPriceNode) {
    const insiderData = state.InsiderData.allPoints;
    if (!insiderData || nodeIndex >= insiderData.length) {
      return trackPriceNode;
    }
    if (!insiderData[nodeIndex]) {
      return trackPriceNode;
    }
    trackPriceNode.insiderBuyColor = this.posDTColor;
    trackPriceNode.insiderSellColor = this.negDTColor;
    insiderData[nodeIndex].forEach((element, index) => {
      if (index === 0 || index === 0) {
        if (element.value !== "0") {
          if (element.UpTick) {
            trackPriceNode.insiderBuy = FormatterUtil.formatNumber(Math.round(element.InsiderVal));
            trackPriceNode.insiderBuyCount = element.InsiderCount;
          }
          else {
            trackPriceNode.insiderSell = FormatterUtil.formatNumber(Math.round(element.InsiderVal));
            trackPriceNode.insiderSellCount = element.InsiderCount;
          }
        }
      }
    });

    return trackPriceNode;
  }
  //End Insider Helper
  getTranslatedTDValue(item) {
    const corpActionType = item.CorpActionType;
    const trDisplay = item.trDisplay;
    let corpActionDisplayValue = item.CorpActionDisplayValue;
    const findS = item.CorpActionDisplayValue.search("S&P");
    if (findS > -1) {
      corpActionDisplayValue = "S&P 500";
    }
    let price = item.Price;
    const currency = item.currency;
    let fullDesc = "";

    switch (corpActionType.toUpperCase()) {
      case CorporateEventsConst.CORPORATEREPURCHASE:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.CORPORATEREPURCHASE] + corpActionDisplayValue;
        break;
      case CorporateEventsConst.SPLIT:
        fullDesc = trDisplay;
        break;
      case CorporateEventsConst.INSIDERBUY:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.INSIDERBUY];
        break;
      case CorporateEventsConst.INSIDERSELL:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.INSIDERSELL];
        break;
      case CorporateEventsConst.CO_CEO:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.CO_CEO];
        break;
      case CorporateEventsConst.DIVIDEND:
        fullDesc = DividendTranslateHelper[corpActionDisplayValue] || (DividendTranslateHelper.DIV + corpActionDisplayValue)
        break;
      case CorporateEventsConst.REPURCHASEANNOUNCEMENT:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.CORPORATEREPURCHASE] + corpActionDisplayValue;
        break;
      case CorporateEventsConst.CEO:
        fullDesc = CorporateTranslateHelper[CorporateEventsConst.CEO];
        break;
      case CorporateEventsConst.MAJORARTICLES:
        fullDesc = MajorArticlesTranslateHelper[corpActionDisplayValue] || corpActionDisplayValue;
        break;
      case CorporateEventsConst.SECURITYANALYSTMEETING:
        fullDesc = corpActionDisplayValue;
        break;
      case CorporateEventsConst.ANNOUNCEMENT:
        price = item.Price.toFixed(2);
        fullDesc = corpActionDisplayValue;
        if (corpActionDisplayValue === AnnouncementConstants.LPE) {
          if (item.Price > 0) {
            fullDesc = LocalizationStore.getTranslatedData("ch_lpefor", `${corpActionDisplayValue} for {0}`, ExtremeDataValue.ReplaceNumberWithCommas(ExtremeDataValue.decimalFormat(item.Price, 0)))
          }
          else {
            fullDesc = AnnouncementTranslateHelper[corpActionDisplayValue]
          }
        }
        else if (AnnouncementTranslateHelper[corpActionDisplayValue]) {
          fullDesc = `${AnnouncementTranslateHelper[corpActionDisplayValue]} ${currency}${price}`;
        }
        break;
      case CorporateEventsConst.ADDEDTOINDEX:
        fullDesc = `${CorporateTranslateHelper[CorporateEventsConst.ADDEDTOINDEX]} ${corpActionDisplayValue}`;
        break;
      case CorporateEventsConst.EARNINGS:
        fullDesc = `${CorporateTranslateHelper[CorporateEventsConst.EARNINGS]}${corpActionDisplayValue}`;
        break;
      default:
        fullDesc = trDisplay ? trDisplay : corpActionDisplayValue;
        break;
    }
    return fullDesc;
  }
  getMAValueItem(maData, index) {
    const maSettings = this.viewsSettings.MASettings[this.majorPeriodicity];
    let movingAverage;
    // const reduxState = ReduxState.getState();
    // const _state = reduxState.DatagraphReducers.PriceChartReducer;
    if (maData) {
      const LineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, maData.maColor) ? "black" : "white";
      movingAverage = (<tr key={`maLine-${index}`}>
        <td className="header">{maSettings[index].maType} <span style={{
          backgroundColor: maData.maColor,
          height: "13px",
          color: LineFontColor,
          paddingLeft: "2px",
          paddingRight: "2px"
        }}> {maSettings[index].ma} </span></td>
        <td>{maData.maPrice}<span style={{ color: maData.maPrChgColor }}> ({maData.maPercChange})</span></td>
      </tr>);
    }
    return movingAverage;
  }
  multiLines(item, key) {
    const rows = [];
    rows.push(<tr key={key}><td style={{ textAlign: "center" }} colSpan="2" className="header">{item.IdeaShortName}</td></tr>);
    key++;
    let action = item.Type === 2 ? TrackPriceTranslateHelper.REMOVED : TrackPriceTranslateHelper.ADDED;
    let price = item.Type === 2 ? item.Last : item.Entry;
    rows.push(<tr key={key}><td className="header">{action}</td><td >{price}</td></tr>);
    if (item.Type === 2) {
      action = TrackPriceTranslateHelper.PERCENT_CHAGNE;
      price = item.IdeaInfoText;
      key++;
      rows.push(<tr key={key}><td className="header">{action}</td><td style={{ color: item.ideaTextColor }}>{price}</td></tr>);
    }
    return rows;
  }
  getPriceChartTemplate(nodeData) {
    let key = 10;
    return <>
      <tr key='8'>
        <td className="header">{TrackPriceTranslateHelper.HIGH}</td>
        <td>{nodeData.high}</td>
      </tr>
      <tr key='9'>
        <td className="header">{TrackPriceTranslateHelper.LOW}</td>
        <td>{nodeData.low}</td>
      </tr>
      {nodeData.maValues && nodeData.maValues.length > 0 && nodeData.maValues.map((maValue, index) => this.getMAValueItem(maValue, index))}
      {nodeData.corporateEvent && nodeData.corporateEvent.map((item) => {
        key++;
        const disp = key > 11 ? "" : TrackPriceTranslateHelper.EVENTS;
        const infoString = this.getTranslatedTDValue(item);
        // Bug 1437 trValue are empty, putting the correct field for display
        return <tr key={key}>
          <td className="header">{disp}</td>
          <td >{infoString}</td>
        </tr>
      })}
      {nodeData.ideasEvent && nodeData.ideasEvent.map((item) => {
        key++;
        return (this.multiLines(item, key));
      })}
      {nodeData.externalDataEvent && nodeData.externalDataEvent.map((item) => {
        key++;
        const disp = TrackPriceTranslateHelper.EVENTS;
        const infoString = `${item.header} ${item.value}`;

        return (
          <tr key={key}>
            <td className="header">{disp}</td>
            <td >{infoString}</td>
          </tr>
        );
      })}
    </>
  }
  getExternalIndicatorTemplate

  getTrackPriceTemplate(nodeData, ShowVolume = false, trackpriceReqArgs) {
    let time;
    if (nodeData.time) {
      time = (<tr key='2'>
        <td className="header">{TrackPriceTranslateHelper.TIME}</td>
        <td >{nodeData.time}</td>
      </tr>
      );
    }
    const last = (<tr key='3'>
      <td className="header">{TrackPriceTranslateHelper.LAST}</td>
      <td>{nodeData.close}</td>
    </tr>);
    return (<table>
      <tbody>
        <tr key='1'>
          <td className="header">{TrackPriceTranslateHelper.DATE}</td>
          <td >{nodeData.date}</td>
        </tr>
        {time}
        {last}
        <tr key='6'>
          <td className="header">{TrackPriceTranslateHelper.CHG}</td>
          <td style={{ color: nodeData.amountChangeColor }}>{nodeData.amountChanged} ({nodeData.pctChange})</td>
        </tr>
        {ShowVolume &&
          <tr key='7'>
            <td className="header">{TrackPriceTranslateHelper.VOL}</td>
            <td>
              <span>{trackpriceReqArgs.chartType === BlockType.Volume ? nodeData.fullVolume : nodeData.volume}</span>
              {trackpriceReqArgs.chartType === BlockType.Pricechart && nodeData.volumePercentChange &&
                <span style={{ color: nodeData.volumeChangeColor }}>{`(${nodeData.volumePercentChange})`}</span>
              }
            </td>
          </tr>
        }
        {ShowVolume && trackpriceReqArgs.chartType !== BlockType.Pricechart && nodeData.volumePercentChange &&
          <tr key='8'>
            <td className="header">{TrackPriceTranslateHelper.RATE}</td>
            <td style={{ color: nodeData.volumeChangeColor }}>{nodeData.volumePercentChange}</td>
          </tr>
        }
        {nodeData.avgVolume && trackpriceReqArgs.chartType === BlockType.Volume &&
          <tr key='9'>
            <td className="header">{TrackPriceTranslateHelper.AVG_VOL}</td>
            <td>{nodeData.avgVolume}</td>
          </tr>
        }
        {nodeData.OpenInterest !== undefined && nodeData.OpenInterest !== null  && trackpriceReqArgs.chartType === BlockType.Volume &&
          <tr key='openInt'>
            <td className="header">OPEN INT</td>
            <td>{nodeData.OpenInterest}</td>
          </tr>
        }
        {trackpriceReqArgs.chartType === BlockType.Insider &&
          <>
            <tr>
              <td> </td>
              <td style={{ textAlign: "left" }}>{TrackPriceTranslateHelper.INSIDERS}</td>
            </tr>
            <tr>
              <td style={{ width: '45%' }} className="header">{TrackPriceTranslateHelper.BUYS_SHRS}</td>
              <tr>
                <td><div style={{ textAlign: "left", width: '40%' }}>{nodeData.insiderBuyCount}</div></td>
                <td><div style={{ marginLeft: "10px", color: nodeData.insiderBuyColor, textAlign: "left", width: '60%' }}>{nodeData.insiderBuy}</div></td>
              </tr>
            </tr>
            <tr>
              <td style={{ width: '45%' }} className="header">{TrackPriceTranslateHelper.SELL_SHRS}</td>
              <tr>
                <td><div style={{ textAlign: "left", width: '40%' }}>{nodeData.insiderSellCount}</div></td>
                <td><div style={{ marginLeft: "10px", color: nodeData.insiderSellColor, textAlign: "left", width: '60%' }}>{nodeData.insiderSell}</div></td>
              </tr>
            </tr>
          </>
        }
        {nodeData.indicatorNode.header && <tr>
          <td style={{ textAlign: "center" }} colSpan="2" className="header symbol-chart-label">{nodeData.indicatorNode.header}</td>
        </tr>}
        {nodeData.indicatorNode.values.map((item) => <tr key={item.key}>
          <td style={{ width: '60%' }} className="header">{item.header}</td>
          <td>
            {item.valueColor && <span style={{
              fontSize: "xx-small",
              backgroundColor: item.valueColor,
              color: item.valueColor
            }}> o </span>}
            <span style={{ marginLeft: 10, color: item.itemColor }}>  {item.value} </span> </td>
        </tr>)}


        {trackpriceReqArgs.chartType === BlockType.Pricechart && this.getPriceChartTemplate(nodeData)}
      </tbody>
    </table>);
  }
}

const TrackPriceHelperFile = new TrackPriceHelper();
export default TrackPriceHelperFile;
import GetCompetitiveEdgeRequest from '../Requests/GetCompetitiveEdgeRequest';
import BaseServiceApi from 'BaseServiceApi'
import AITextFeedbackRequest from '../Requests/AITextFeedbackRequest';
import GetZigzagTrendDataRequest from '../Requests/GetZigzagTrendDataRequest';

class AINewsApi {
    getCompetitiveEdgeRequest(data,  osid, companyName) {
        const req = new GetCompetitiveEdgeRequest(data,  osid, companyName);
        return BaseServiceApi.processAIServiceCall(req)
    }

    aiTextFeedbackRequest(data) {
        const req = new AITextFeedbackRequest(data);
        return BaseServiceApi.processAIServiceCall(req)
    }
    getZigzagTrendDataRequest(symbol, startDate, endDate, trendType,  osid, companyName) {
        const req = new GetZigzagTrendDataRequest(symbol, startDate, endDate, trendType,  osid, companyName);
        return BaseServiceApi.processAIServiceCall(req)
    }
}

var aiNewsApi = new AINewsApi();
export default aiNewsApi;
import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetCompetitiveEdgeRequest extends BaseServiceRequest {
  constructor(symbol, osid, companyName) {
    super();
    super.addRequestUri("getcompetitiveanalysis");
    super.addRequestParameter("req_symbol", symbol);
    super.addRequestParameter("osid", osid);
    super.addRequestParameter("company_name", companyName);

  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  // getMessageName() {
  //   return "CompetitiveEdgeResponseData";
  // }
}

export default GetCompetitiveEdgeRequest;

import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AITextFeedbackRequest extends BaseServiceRequest {
  constructor(data) {
    super();
    super.addRequestUri("feedback");
    const reqData = {};
    reqData.feedback_type = data.reaction
    reqData.comments = data.comments
    reqData.feedback_description = data.text
    reqData.ai_type_id = data.newsType
    reqData.start_date = data.startDate
    reqData.end_date = data.endDate
    reqData.trend = data.trend
    reqData.OSID = data.OSID
    reqData.periodicity = data.periodicity
    reqData.news_date = data.newsDate
    super.setContent(JSON.stringify(reqData))
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }
}

export default AITextFeedbackRequest;

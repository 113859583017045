import React from 'react';
import { connect } from 'react-redux';
import { setReaction } from '../../Actions/AINewsActions';
import ReactMarkdown from 'react-markdown';
import AIReactionModal from './AIReactionModal';
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "UserInfoUtil";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const AISummaryContent = ({ data, setReaction, newsType, selectedSegment }) => {

  const isAIFeedbackEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.AI_FEEDBACK_Entitlement)
  function isDate(text) {
    if (typeof text !== 'string') {
      return false;
    }
    const date = new Date(text);
    if (isNaN(date.getFullYear()) || isNaN(date.getDay()) || isNaN(date.getMonth())) {
      return false; 
    }
    return true;
  }

  const getParentText = (children) => {
    let text = extractText(children)
    return (isDate(text)) ? text: '';
  }

  const handleReaction = (text, reaction, comments, date) => {
    setReaction(reaction, comments, text, newsType, selectedSegment, date);
  };

  function extractText(children) {
    let text = "";

    React.Children.forEach(children, (child) => {
      if (typeof child === 'string') {
        text += child;
      } else if (React.isValidElement(child)) {
        text += extractText(child.props.children);
      }
    });
  
    return text.trim();
  }

  const renderContent = () => {
    let lastParentText = "";
    return (
      <ReactMarkdown
        components={{
          p: ({ children }) => {
            lastParentText = getParentText(children);
            return <p>{children}</p>;
          },
          h1: ({ children }) => {
            lastParentText = getParentText(children);
            return <h1>{children}</h1>;
          },
          h2: ({ children }) => {
            lastParentText = getParentText(children);
            return <h2>{children}</h2>;
          },
          h3: ({ children }) => {
            lastParentText = getParentText(children);
            console.log(children, lastParentText)
            return <h3>{children}</h3>;
          },
          h4: ({ children }) => {
            lastParentText = getParentText(children);
            return <h4>{children}</h4>;
          },
          h5: ({ children }) => {
            lastParentText = getParentText(children);
            return <h5>{children}</h5>;
          },
          h6: ({ children }) => {
            lastParentText = getParentText(children);
            return <h6>{children}</h6>;
          },
          li: ({ children, ...props }) => {
            const text = extractText(children);
            return (
              <li {...props} style={{ listStyleType: isAIFeedbackEntitled ? 'none' : '', margin: '5px 0px', display: isAIFeedbackEntitled ? 'flex' : '', gap: '5px' }}>
                { isAIFeedbackEntitled &&
                  <AIReactionModal text={text} handleReaction={handleReaction} parentText={lastParentText} />
                }
                <p>{children}</p>
              </li>
            )
          },
          ul: ({ children, ...props }) => (
            <ul {...props} style={{ paddingLeft: isAIFeedbackEntitled ? '5px' : '20px' }}>{children}</ul>
          )
        }}
      >
        {data}
      </ReactMarkdown>
    );
  };


  return (
    <div className="space-y-4">
      {renderContent()}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
    setReaction: (reaction, comments, text, newsType, selectedSegment, newsDate) => dispatch(setReaction(reaction, comments, text, newsType, selectedSegment, newsDate))
});

export default connect(null, mapDispatchToProps)(AISummaryContent);

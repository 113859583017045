module.exports = {
  "262626": "P262626CCCCCC",
  "330000": "P330000FDBCBC",
  "333300": "P333300FFFF9A",
  "340033": "P340033CDCCFF",
  "340066": "P3400669866FF",
  "660099": "P6600996734CC",
  "666777": "#777",
  "666888": "#666",
  "670000": "P670000FF0000",
  "676700": "P676700FFFF00",
  "990000": "P990000CC0100",
  "999000": "#999",
  "999900": "P999900CBCB01",
  "999999": "P9999994C4C4C",
  "sprite": "/Asset/images/darkSprite.png",
  "sprite2x": "/Asset/images/darkSprite2x.png",
  "imageSprite": "/Asset/images/imgSprite.png",
  "chartGridColor": "#262626",
  "eighteenMnth": "#333333",
  "fff333": "#fff",
  "fff000": "#fff",
  "000fff": "#000",
  "fff666": "#fff",
  "ddd333": "#ddd",
  "999ccc": "#999",
  "f1f1f1EBEBEB": "#1f1f1f",
  "E1E20FEFEF0": "#1E1E20",
  "999999333333": "#999999",
  "1E1E1FDBDCDC": "#1E1E1F",
  "2C2C2CE9E9E9": "#2C2C2C",
  "000000F2F2F2": "#000000",
  "E0E189FF6700": "#E0E189",
  "CCCCCC262626": "#CCCCCC",
  "000000999999": "#000000",
  "999999000000": "#999999",
  "191919666666": "#191919",
  "3B3C42CCCCCC": "#3B3C42",
  "77CAFE04478A": "#77CAFE",
  "scaleText": "#CCCCCC",
  "insiderLine": "#42424a",
  "dateText": "#CCCCCC",
  "dividerColor": "#31313a",
  "overBought": "#670000",
  "overSold": "#006600",
  "PTOIndex": "#4C4C4C",
  "PTOEStock": "#FFCC99",
  "PTOSStock": "#99FF99",
  "grayCmLb": "gray",
  "sma10": "#0066CC",
  "tiLabel": "#cccccc",
  "000000666666": "#666666",
  "applicationHtml": "#191922",
  "applicationBody": "#161618",
  "AppBackground": "#000000",
  "AppSecondaryBackground": "#101019",
  "activeHighlightBackground": "#b8daff",
  "textDefault": "#999999",
  "textTitles": "#ffffff",
  "inactiveText": "#333333",
  "linkText": "#77cafe",
  "linkTextMouseover": "#ffffff",
  "positiveDataText": "#018fee",
  "positiveDataTextHover": "#77cafe",
  "negativeDataText": "#ff33cc",
  "negativeDataTextHover": "#fd7ef2",
  "estimateDataText": "#E0E189",
  "positiveNode": "#0065d1",
  "negativeNode": "#f0009d",
  "textNewsStemp": "#ccc",
  "grayText": "#888888",
  "dogear": "#FAFD02",
  "headerTabNavBg": "#161618",
  "tabLabel": "#999999",
  "tabBg": "#161618",
  "tabBgHover": "#4c4c51",
  "tabBgActive": "#4c4c51",
  "tabDivider": "#444444",
  "tabDividerHover": "#444444",
  "tabLabelHover": "#ffffff",
  "tabLabelActive": "#ffffff",
  "tabSearchInputBg": "#FFF",
  "tabSearchInputTxt": "#000",
  "volRateLabel": "#999999",
  "buttonLabel": "#ffffff",
  "buttonLabelShadow": "#000000",
  "buttonBgTop": "#42424b",
  "buttonBgTopHover": "#595a63",
  "buttonBgBottom": "#283031",
  "buttonBgBottomHover": "#494952",
  "buttonStroke": "#000000",
  "buttonLabelGray": "#999999",
  "buttonLabelHover": "#ffffff",
  "buttonLabelShadowHover": "#000000",
  "ideasBgTop": "#42424b",
  "ideasBgTopHover": "#595a63",
  "ideasBgBottom": "#283031",
  "ideasBgBottomHover": "#494952",
  "buttonLabelActive": "#000000",
  "buttonLabelShadowActive": "#b8daff",
  "buttonBgActive": "#b8daff",
  "buttonLabelInactive": "#999999",
  "buttonLabelShadowInactive": "#000000",
  "buttonBgTopInactive": "#42424b",
  "buttonBgBottomInactive": "#283031",
  "buttonSecondaryLabel": "#333333",
  "buttonSecondaryBgTop": "#f6f6f6",
  "buttonSecondaryBgBottom": "#dbdbdb",
  "buttonSecondaryBgBottomHover": "#f6f6f6",
  "buttonSecondaryStroke": "#666666",
  "buttonInfoBgTop": "#385f77",
  "buttonInfoBgTopHover": "#3797e0",
  "buttonInfoBgBottom": "#183343",
  "buttonInfoBgBottomHover": "#1b5e9b",
  "financialDividerLines": "#2D343B",
  "contextMenuText": "#000000",
  "contextMenuBg": "#ececec",
  "contextMenuStroke": "#b3b3b3",
  "contextMenuListHover": "#bddeff",
  "contextMenuInactiveText": "#666666",
  "contextMenuDividers": "#b3b3b3",
  "contextMenuCheckmark": "#000000",
  "contextMenuSecondaryLinks": "#006699",
  "contextMenuListHoverTop": "#c8e3fe",
  "contextMenuListHoverBottom": "#a9d2fe",
  "fundamentalConsoleBlockTitleTop": "#37393d",
  "fundamentalConsoleBlockTitleBottom": "#0e1116",
  "fundamentalConsoleRowtableBgHover": "#2B2F34",
  "fundamentalConsoleSearchResultBg": "#000000",
  "fundamentalConsoleValue": "#77CAFF",
  "fundamentalConsoleTitle": "#bdd683",
  "tableHeaderBgTop": "#000",
  "tableHeaderBgBottom": "#000",
  "fundamentalConsoleExploreListBg": "#37393d",
  "fundamentalConsoleExploreListColor": "#d0d0d0",
  "headCellTableHeaderTop": "#575a48",
  "headCellTableHeaderBottom": "#3a3c31",
  "fundamentalConsoleSortingTop": "#42454a",
  "fundamentalConsoleSortingBottom": "#2e3136",
  "fundamentalConsoleSortingTopHover": "#606267",
  "fundamentalConsoleSortingBottomHover": "#44474d",
  "fundamentalRadioBorder": "#333",
  "headTableGreenHeading": "#BDD396",
  "headTableGrayTxt": "#CACACA",
  "fundamentalFrozenBorder": "#000",
  "fundamentalValueLessDay": "#bdd683",
  "fundamentalValueLessDayHover": "#fff",
  "zebraStripeColor": "#0E1216",
  "footerBg": "#161618",
  "footerTabBgTop": "#424242",
  "footerTabBgBottom": "#191919",
  "footerTablinkText": "#77cafe",
  "footerTabActive": "#000",
  "footerTabDisabled": "#A9A9A9",
  "footerTabBorder": "#444",
  "footerlogoWrapBorder": "#000",
  "footerTablinkTextShadow": "#000000",
  "logoWrapBorderOne": "#5a5d5a",
  "logoWrapBorderTwo": "#000000",
  "versionAppText": "#999",
  "gridRowActiveBg": "#2C4258",
  "pointerColor": "#3b3c42",
  "riPanelBg": "#161618",
  "riBorderColor": "#161719",
  "riActionBg": "#3C3E42",
  "startGradientColor": "#3b3c42",
  "endGradientColor": "#27292c",
  "ownershipBorderColor": "#999999",
  "ownershipHeaderBg": "#1E1E1F",
  "cellActiveBg": "#414347",
  "idealistWellBg": "#1f1f1f",
  "idealistWellText": "#999999",
  "holdingAmountBg": "#45474B",
  "indicatorBrush1": "#46D9B2",
  "indicatorBrush2": "#FF8A48",
  "indicatorBrush3": "#888888",
  "activeFundBg": "#2F3A4F",
  "sideBarStartColor": "#716d6d",
  "sideBarEndColor": "#000000",
  "sideBarHoverStartColor": "rgba(113, 113, 113, 0.94)",
  "sideBarHoverEndColor": "rgba(25, 25, 25, 1)",
  "newsItemBorderColor": "#26A0DA",
  "allocationFontColor": "#CCCCCC",
  "ideaListFlagButtonBg": "#1F1F1F",
  "ideaListFlagActiveButtonBg": "#191919",
  "entitlementBtnStartBg": "#3a3a42",
  "entitlementBtnEndBg": "#293031",
  "entitlementBorderBrush": "#444444",
  "sectorWeightingGraphColor": "#464646",
  "sectorWeightingGraphBarColor": "#ACD166",
  "newsReloadColor": "#90EE90",
  "idealistflagBorderColor": "#333333",
  "riPanelBorderColor": "#666666",
  "riPanelHeaderStartBg": "#cfd4dd",
  "riPanelHeaderEndBg": "#767a7e",
  "periodicityBGBrushStart": "#43464B",
  "periodicityBGBrushStop": "#2E3136",
  "hubNavigationLabel": "#cccccc",
  "hubFocusText": "#ffffff",
  "hubNormalText": "#A1AEB5",
  "hubText": "#A1AEB5",
  "hubLiPoint": "white",
  "A9A9A9262626": "#A9A9A9",
  "miniListPanelBg": "#F7F7F7",
  "miniListName": "#77cafe",
  "miniListItemColor": "#999999",
  "miniListNameHover": "#cccccc",
  "sideBarDotsColor": "#6d6666",
  "miniListSelectedBg": "#37393D",
  "miniListSelectedBdr": "#666",
  "miniListSelectedHover": "#fff",
  "miniListDividerBorderColor": "#485461",
  "miniListRepeatArrowColorA": "#FFFFFF",
  "miniListRepeatArrowColorB": "#999999",
  "chartSeparatorBorder": "#30373F",
  "financialTitleBar": "#000",
  "financialHorizontalGridBg": "#2B2F34",
  "financeBlockTitleBarBgTop": "#646973",
  "financeBlockTitleBarBgBottom": "#646973",
  "financeBlockTitleColor": "#77CAFF",
  "activePage": "#fff",
  "inActivePage": "#666",
  "summaryBlockBg": "#171D24",
  "summaryBlockTitleBg": "#2B313A",
  "summaryBlockSwatchColor": "#999",
  "summaryBlockInfoRowBg": "#2B333E",
  "summaryBlockBorderColor": "#485461",
  "summaryBlockHeaderText": "#dedede",
  "summaryBlockHeaderCell": "#afafaf",
  "summaryBlockHeaderCellBg": "#2B333D",
  "summaryBlockWeeklyHover": "#2B2F34",
  "summaryBlockWeeklyText": "#c7c7c7",
  "summaryBlockWeeklyBg": "#2B333D",
  "SummaryBlockCarouselIndicatorsBg": "#808080",
  "summaryBlockCRIcon": "-200px -200px",
  "summaryBlockDogEarBg": "yellow",
  "summaryBlockDogEarColor": "#000",
  "summaryBlockDeBorderBg": "#FFFF99",
  "summaryBlockBackgroundPosition": "-50px -33px",
  "summaryBlockBackgroundHoverPosition": "-50px 0",
  "summaryBlockLoadingBackGround": "/Asset/images/SummaryBlock_Bg_Dk.png",
  "summeryBlockIndustryGroupText": "#CCC",
  "tick": "/Asset/images/tick.png",
  "verticalScrollMiddle": "#525252",
  "verticalScrollMiddleBorder": "#000000",
  "verticalScrollBackground": "#292929",
  "verticalScrollBorder": "#292929",
  "horizontalScrollMiddle": "#525252",
  "horizontalScrollMiddleBorder": "#000000",
  "horizontalScrollBackground": "#292929",
  "horizontalScrollBorder": "#292929",
  "ScrollVerticalUp": "-450px -150px",
  "ScrollVerticalDown": "-475px -150px",
  "ScrollHorizontalLeft": "-450px -175px",
  "ScrollHorizontalRight": "-475px -175px",
  "loadingSpinner": "/Asset/images/loading-dark.gif",
  "layoutLoadingSpinner": "/Asset/images/loading-dark.gif",
  "pePsBgTop": "#090909",
  "pePsBgBottom": "#3d3d3d",
  "pePsBorder": "#30373F",
  "pePsColor": "#928e8e",
  "blockTitleBarBg": "#000",
  "tabViewBgTop": "#212225",
  "tabViewBgBottom": "#323337",
  "tabViewBgBorder": "#7f7f7f",
  "tabViewBottomFooterTop": "#323337",
  "tabViewBottomFooterBottom": "#212225",
  "groupFilterColor": "#a9a9a9",
  "groupFilterBadgeElementColor": "#a9a9a9",
  "groupFilterBg": "#0e0e0e",
  "gridHeaderTop": "#323338",
  "gridHeaderBottom": "#141518",
  "gridHeadeHoverTop": "#878992",
  "gridHeaderHoverBottom": "#404248",
  "gridHeadeActiveTop": "#54555C",
  "gridHeaderActiveBottom": "#26272b",
  "gridHeaderActiveBorder": "#000000",
  "sortingOrder": "#91d072",
  "gridloading": "#1F2022",
  "gridRowOddBg": "#161719",
  "gridRowEvenBg": "#000",
  "gridRowOddEvenColor": "#a9a9a9",
  "gridCellSymbolText": "#77cafe",
  "treeViewItemHover": "#37393D",
  "oNeilTabActive": "#444",
  "groupListInnerSection": "#4B4B4B",
  "containerSwitchTab": "#333",
  "groupListInnerBlock": "#161719",
  "groupBoxBorder": "#535353",
  "groupBoxHover": "#414B56",
  "bottomTabBrowserFilterBg": "#4B4B4B",
  "bottomTabBrowserFilterBgInactive": "#333",
  "bottomTabBrowserFilterBgHover": "#4B4B4B",
  "bottomTabBrowserFilterInactiveColor": "#999999",
  "gridRowSelected": "#3d3d3d",
  "gridRowSelectedBorder": "#eee",
  "listPropertiesBg": "#14171A",
  "columnSetTitle": "#999999",
  "listBg": "#000",
  "listActiveText": "#000",
  "filterBadgeElement": "#77cafe",
  "listPropertiesDetails": "#B1B1B1",
  "oNeilTabActiveBorder": "#999",
  "oNeilTabActiveColor": "#fff",
  "listGridloader": "#000",
  "symbolEntryBorderColor": "#444444",
  "symbolEntryMenuBorderColor": "#cccccc",
  "footerRotationBG": "#2b2b2d",
  "footerBottomYear": "#454545",
  "rotationCycleArrow1": "#222222",
  "rotationCycleArrow2": "#262626",
  "rotationAxisLine": "#333333",
  "headCenterRotation": "#999",
  "headHighLevelRotation": "#999",
  "headLowLevelRotation": "#666",
  "RotationsSymbolName": "#a5a5a5",
  "RotationsRiPanelBde": "#222",
  "RotationsRiPanelHeadTopBdr": "#515151",
  "RotationsRiPanelHeadBottompBdr": "#5a5a5a",
  "graphChartAreaPathBg": "#454545",
  "graphChartAreaPathBorder": "#999",
  "graphListActionButtonBg": "#222",
  "graphListActionButtonBorder": "#5a5a5a",
  "graphListActionButtonBgCustomtop": "#42424b",
  "graphListActionButtonBgCustomButtom": "#283031",
  "graphListActionButtonBgCustomtopHover": "#595a63",
  "graphListActionButtonBgCustomButtomHover": "#494952",
  "compareRiPanelBorder": "#5C5C5C",
  "graphtabBg": "#3A3A43",
  "RotationsHeadTopBdr": "#515151",
  "rangeThumbHandle": "-350px -450px",
  "rangeThumbHandleHover": "-425px -450px",
  "rangeThumbHandleActive": "-500px -450px",
  "uptrendStroke": "#0066CC",
  "downtrendStroke": "#CC0099",
  "defaultScaleText": "#ffffff",
  "labelRotation": "#fff",
  "labelRotationGrey": "#999",
  "00339904478a": "#003399",
  "a5a5a5333333": "#a5a5a5",
  "77cafe04478a": "#77cafe",
  "ffffff333333": "#ffffff",
  "333333d3d3d3": "#333333",
  "77cafe003399": "#77cafe",
  "0f0f109c9d9f": "#0f0f10",
  "defaultKPIcolor": "#999999",
  "P000000FFFFFF": "#000000",
  "P262626CCCCCC": "#262626",
  "P4C4C4C999999": "#4C4C4C",
  "P9999994C4C4C": "#999999",
  "PCCCCCC262626": "#CCCCCC",
  "PFFFFFF000000": "#FFFFFF",
  "P00333399FFFF": "#003333",
  "P00666633FFFF": "#006666",
  "P00999900CCCC": "#009999",
  "P00CCCC009999": "#00CCCC",
  "P33FFFF006666": "#33FFFF",
  "P99FFFF003333": "#99FFFF",
  "P00003399CCFF": "#000033",
  "P013CB366CCFF": "#013CB3",
  "P0066CC3399FF": "#0066CC",
  "P3399FF0066CC": "#3399FF",
  "P66CCFF013CB3": "#66CCFF",
  "P99CCFF000033": "#99CCFF",
  "P013CB3013CB3": "#013CB3",
  "P000000111111": "#000000",
  "P340033CDCCFF": "#340033",
  "P3400669866FF": "#340066",
  "P6600996734CC": "#660099",
  "P6734CC660099": "#6734CC",
  "P9866FF340066": "#9866FF",
  "PCDCCFF340033": "#CDCCFF",
  "P330000FDBCBC": "#330000",
  "P670000FF0000": "#670000",
  "P990000CC0100": "#990000",
  "PCC0100990000": "#CC0100",
  "PFF0000670000": "#FF0000",
  "PFDBCBC330000": "#FDBCBC",
  "P5B1B00FFCC99": "#5B1B00",
  "PAE3E00FDA57D": "#AE3E00",
  "PFC6901FD8548": "#FC6901",
  "PFD8548FC6901": "#FD8548",
  "PFDA57DAE3E00": "#FDA57D",
  "PFFCC995B1B00": "#FFCC99",
  "P333300FFFF9A": "#333300",
  "P676700FFFF00": "#676700",
  "P999900CBCB01": "#999900",
  "PCBCB01999900": "#CBCB01",
  "PFFFF00676700": "#FFFF00",
  "PFFFF9A333300": "#FFFF9A",
  "P00330099FF99": "#003300",
  "P00660034FF33": "#006600",
  "P08980009CB01": "#089800",
  "P09CB01089800": "#09CB01",
  "P34FF33006600": "#34FF33",
  "P99FF99003300": "#99FF99",
  "000000": "P000000FFFFFF",
  "4C4C4C": "P4C4C4C999999",
  "CCCCCC": "PCCCCCC262626",
  "FFFFFF": "PFFFFFF000000",
  "003333": "P00333399FFFF",
  "006666": "P00666633FFFF",
  "009999": "P00999900CCCC",
  "00CCCC": "P00CCCC009999",
  "33FFFF": "P33FFFF006666",
  "99FFFF": "P99FFFF003333",
  "000033": "P00003399CCFF",
  "013CB3": "P013CB366CCFF",
  "0066CC": "P0066CC3399FF",
  "3399FF": "P3399FF0066CC",
  "66CCFF": "P66CCFF013CB3",
  "99CCFF": "P99CCFF000033",
  "6734CC": "P6734CC660099",
  "9866FF": "P9866FF340066",
  "CDCCFF": "PCDCCFF340033",
  "CC0100": "PCC0100990000",
  "FF0000": "PFF0000670000",
  "FDBCBC": "PFDBCBC330000",
  "5B1B00": "P5B1B00FFCC99",
  "AE3E00": "PAE3E00FDA57D",
  "FC6901": "PFC6901FD8548",
  "FD8548": "PFD8548FC6901",
  "FDA57D": "PFDA57DAE3E00",
  "FFCC99": "PFFCC995B1B00",
  "CBCB01": "PCBCB01999900",
  "FFFF00": "PFFFF00676700",
  "FFFF9A": "PFFFF9A333300",
  "003300": "P00330099FF99",
  "006600": "P00660034FF33",
  "089800": "P08980009CB01",
  "09CB01": "P09CB01089800",
  "34FF33": "P34FF33006600",
  "99FF99": "P99FF99003300",
  "ownershipTabStuffBg": "#000",
  "ownershipDigitboxTxt": "#9EA8AF",
  "ownershipDigitboxBorder": "#5D5C5A",
  "ownershipTblBg": "#2B2E33",
  "ownershipInsideDomTab": "#808080",
  "ownBlackGridbgOdd": "#000",
  "ownBlackGridbgEven": "#161719",
  "ownGrayGridbgOdd": "#25242B",
  "ownGrayGridbgEven": "#282B2F",
  "ownershipHeadPass": "#9EA8AF",
  "ownershipContentHolder": "#2B2E33",
  "ownershipContentGridBg": "#2B2E33",
  "ownershipGridScrollSeprationLine": "#000",
  "ownershipHeaderBottomBorderColor": "#313840",
  "ownershipScrollPath": "#2B2E33",
  "ownershipScrollPathHover": "#2A2A2A",
  "ownershipGridAlterBlackBorderEvenLight": "#1F2124",
  "ownershipGridAlterGrayBorderEvenLight": "#232629",
  "ownershipGridAlterBlackBorderEvenDark": "#121315",
  "ownershipGridAlterGrayBorderEvenDark": "#1C1D20",
  "ownershipCellHoverColor": "#8CCAEA",
  "ownershipGridHeadHoverLeft": "#878992",
  "ownershipGridHeadHoverRight": "#46484E",
  "ownershipGridHeadSelectiveLeft": "#505259",
  "ownershipGridHeadSelectiveRight": "#35383d",
  "ownershipGridHeadHoverSelctiveTextColor": "#FFF",
  "ownershipOrderCounter": "#02AD02",
  "ownershipSelectedHighlight": "#BDDE7A",
  "ownershipClosingBtn": "#f9f9f9",
  "ownershipTabStuffTabText": "#FFF",
  "ownershipTabStuffTabBorder": "#444",
  "ownershipTabStuffTabTopColor": "#424242",
  "ownershipTabStuffTabBotColor": "#191919",
  "ownershipTabStuffTabshadow": "#3f3f3f",
  "ownershipTabStuffTabTopColorHover": "#606166",
  "ownershipTabStuffTabBotColorHover": "#46494E",
  "ownershipTabStuffTabshadowHover": "#54555a",
  "ownershipTabStuffTabBorderHover": "#46494E",
  "ownershipTabStuffTabColorActive": "#B8DAFF",
  "ownershipTabStuffTabBorderActive": "#AAD1FA",
  "ownershipTabClosingIcon": "-472px -97px",
  "ownershipTabClosingIconHover": "-472px -122px",
  "RIPanelOwnershipEntry": "#5C5C5C",
  "backBtnBg": "-550px -50px",
  "backBtnBgHover": "-550px -75px",
  "checkListboxBorder": "#333333",
  "checklistItemHover": "#3B3B3B",
  "checklistInfoBg": "#3B3E43",
  "checkListIsThreshold": "#161618",
  "ListgraphAreaMain": "#232428",
  "ListgraphAreaSecond": "#000000",
  "ListgraphAreaBorder": "#414143",
  "listResizeBarGraphBorderBottom": "#000",
  "listResizeBarGraphBorderTop": "#3C3C3D",
  "listResizeBarGraphBorderBg": "#2b2b30",
  "listResizeBarGraphBorderLinerGradientMain": "#2b2b30",
  "listResizeBarGraphBorderLinerGradientSecond": "#212224",
  "barGraphinnerbg": "#262626",
  "barGraphinnerStrock": "#666666",
  "barToolTipBackground": "#000000",
  "barToolTipForground": "#ffffff",
  "listSummaryStatsDataOdd": "#1a1a1a",
  "listSummaryStatsDataEven": "#2c2c2e",
  "financialHover": "#2b2f34",
  "financialSpellHover": "#77CAFF",
  "financialIconBg": "#c8daef",
  "financialWithActive": "#2b2f34",
  "financialSpellWithActiveHover": "#77CAFF",
  "financialIconBgWithActive": "#2b2f34",
  "financialIconBgWithActiveHover": "#c8daef",
  "financialClicked": "#A0A0A0",
  "financialSpellClickedHover": "#77CAFF",
  "financialIconBgClickedHover": "#c8daef",
  "paginateViewGraph": "#000",
  "fundamentalIconBgForHover": "#2b2f34",
  "imageOfChanger": "-225px -100px",
  "BgOfChanger": "#77CAFE",
  "HoverBgOfChanger": "#FFF",
  "imageOfFinancial": "-245px -99px",
  "hoverImageOfFinancial": "-245px -124px",
  "imageFinancialClicked": "-245px -99px",
  "normalHoverImageOfFinancial": "-245px -99px",
  "fefbce": "#fefbce",
  "8f8e7a": "#8f8e7a",
  "000": "#000",
  "summeryStratcer": "#3a3a3a",
  "summeryStateHoldBG": "#616363",
  "summeryStateHoldColor": "#fff",
  "summeryStateHoldBGHover": "#b5b5b5",
  "summeryStateHoldColorHover": "#000",
  "summaryBlockBgPos": "-200px 0",
  "summaryBlockBgPosHover": "-200px -25px",
  "summaryBlockBgPosActiveHover": "-200px -25px",
  "listSummaryBlockTitle": "#616363",
  "listSummaryBlockTitleHover": "#D6D6DF",
  "listSummaryBlockTitleHoverColor": "#000",
  "electricUtilitiesTopBorder": "#3c3c3d",
  "crossHairWindowBG": "#1e1e20",
  "crossHairWindowBorderColor": "#333",
  "crossHairLabel": "#999",
  "crossHairLabelValue": "#ccc",
  "newsBlockBg": "#161618",
  "newsDetailsTabData": "#999",
  "NewslistGroupItemEven": "#292929",
  "NewslistGroupItemOdd": "#191919",
  "newsBlockTitleBarBgTop": "#646973",
  "newsBlockTitleBarBgBottom": "#646973",
  "newsMaintabMainBlock": "#232323",
  "newsFillAreaContent": "#000",
  "metrickDialogBg": "#202022",
  "metrickDialogHeaderTitleColor": "#CCC",
  "metrickDialogHeaderTopBorderColor": "#424242",
  "metrickDialogHeaderTopColor": "#424242",
  "metrickDialogHeaderbottomColor": "#151515",
  "metrickDialogHeaderbottomBorderColor": "#151515",
  "metrickDialogBodyTopBorderColor": "#767B81",
  "metrickInnerBlockBg": "#515151",
  "metrickBtnDefaultTextColor": "#FFF",
  "metrickBtnDefaultTextShadowColor": "#000",
  "metrickBtnDefaultborder": "#000",
  "metrickBtnDefaultBgTopColor": "#4F4F50",
  "metrickBtnDefaultBgBottomColor": "#2E3033",
  "metrickBtnDefaultBgTopColorHover": "#606166",
  "metrickBtnDefaultBgBottomColorHover": "#46494E",
  "metrickLabelTextColor": "#FFF",
  "comDialogContentBorderColor": "#767B81",
  "comDialogContentBG": "#202022",
  "comDialogContentTextColor": "#FFF",
  "MsgDefMainWrap": "#808080",
  "MsgTabNavBg": "#6C6F74",
  "MsgTabNavTextColor": "#fff",
  "MsgTabNavBgHover": "#515151",
  "MsgTabNavTextColorHover": "#fff",
  "MsgTabNavBgColorActive": "#515151",
  "MsgTabNavBoxBG": "#202022",
  "MsgTabNavBoxBottomBorderColor": "#515151",
  "MsgTabNavContentBg": "#515151",
  "MsgTabNavContentThBg": "#A0A0A0",
  "MsgTabNavContentThTextColor": "#000",
  "MsgTabNavContentTdBg": "#515151",
  "MsgTabNavContentTdTextColor": "#bcb9b9",
  "MsgTabNavContentTdTextColorUnreadDetail": "#FFF",
  "MsgTabNavContentTdTextColorUnreadName": "#77cafe",
  "MsgTabNavContentTdTextColorUnreadNameHover": "#FFF",
  "MsgTabNavContentTdTextColorReadDetail": "#A5A5A5",
  "MsgTabNavContentTdTextColorReadName": "#A5A5A5",
  "MsgTabNavContentTdTextColorReadNameHover": "#FFF",
  "MsgTabNavContentTdTextColorReadTrHover": "#FFF",
  "MsgTabNavContentTdTextColorTrHover": "#FFF",
  "MsgBlueTextColor": "#77cafe",
  "MsgBlueTextColorHover": "#fff",
  "notifyInnerChildBg": "#E8E8E8",
  "notifyInnerChildTextColor": "#000",
  "notifyInnerChildHLStockNameTextColor": "#158ce0",
  "notifyInnerChildHLStockNameTextColorHover": "#000",
  "notifyInnerChildHLStockDetailsTextColor": "#000",
  "notifyInnerChildCloseColor": "#158ce0",
  "notifyInnerChildCloseColorHover": "#000",
  "MsgDefTTBorderColor": "#666",
  "MsgDefTTBg": "#FEFBCE",
  "MsgDefTTTxtColor": "#000",
  "alePopLGIBg": "#fff",
  "alePopLGIBorderColor": "#CCCCCC",
  "alePopLGIActiveBg": "#B8DAFE",
  "alePopLGIPanelAColor": "#000",
  "PrefBtnColor": "#00528d",
  "ownerShipBlockMainPanelTopBorder": "#30373F",
  "ownershipBlockHeaderBG": "#000",
  "ownershipBlockRightHeaderLeftBorder": "#2D343B",
  "ownershipGridDataHoldBg": "#000",
  "ownershipGridDataBG": "#000",
  "ownershipGridDataRowHoverBG": "#2B2F34",
  "ownershipGridDataRowHoverBorder": "#656A70",
  "ownershipGridRowBorder": "#2D343B",
  "ownershipGridRowBG": "#000",
  "ownershipGridRowColor": "#CCC",
  "ownerTogglerBoxBG": "#0E1216",
  "ownerTogglerBoxBorder": "#30373F",
  "ownerTogglerCellFont": "#FFF",
  "ownershipGridPercentileFont": "#fff",
  "ownershipBlockleftHeading": "#77CAFF",
  "ownershipBlockrightYTDHeading": "#808080",
  "yellowDogear": "#FF0",
  "yellowDogearHover": "#FF0",
  "yellowDogearText": "#000",
  "yellowDogearHoldBG": "#FFFF99",
  "yellowDogearHoldBorder": "#000",
  "ownershipValueChanger": "#FFF",
  "loftBg": "#676b6f",
  "loftBgHover": "#d4d4d8",
  "ownershipBrecketsDottedBorder": "#77CAFF",
  "blackTooltipBg": "#000",
  "blackTooltipColor": "#DDD",
  "blackTooltipBorder": "#ccc",
  "blackTooltipShadow": "#BBB",
  "dataGraphTooltip": "#FEFBCE",
  "ownershipSubmenulistAction": "#369",
  "ownershipSubmenulistActionHover": "#000",
  "subOwnershiopText": "#000",
  "ownershipActionLInkSepretor": "#333",
  "ownershipGridDataRowDefaultColor": "#808080",
  "oneilRatingBtnBorder": "#CCC",
  "oneilRatingBtnBG": "#BABEC2",
  "oneilRatingBtnBorderIside": "#999",
  "oneilRatingBtnColor": "#000",
  "oneilRatingBtnHoverBG": "#BABEC2",
  "oneilRatingBtnHoverColor": "#000",
  "oneilRatingBtnActiveBG": "#ECECEC",
  "oneilRatingBtnActiveColor": "#000",
  "oneilRatingBtnBorderActiveHoverIside": "#999",
  "oneilRatingTabContentBorder": "#CCC",
  "oneilRatingTabContentBG": "#ECECEC",
  "oneilRatingTabContentPaneBorder": "#8B929B",
  "oneilRatingRestoreLinkColor": "#039",
  "oneilRatingRestoreLinkHoverColor": "#000",
  "oneilRatingApearanceBGColor": "#EBEBEB",
  "oneilRatingApearanceBorderColor": "#8B929A",
  "oneilRatingMACDMeasureCriteria": "#555",
  "oneilAboutRowBottomBorder": "#AFB3B6",
  "oneilAboutRowtopBorder": "#FFF",
  "oneilRatingLabelText": "#000",
  "oneilRatingMacdInputText": "#000",
  "accdisTiIndicatorholdBg": "#fff",
  "accdisTiIndicatorholdBorder": "#D5D6D8",
  "accdisTiIndicatorListColor": "#000",
  "liningParent": "#000",
  "liningParentBg": "#B4B4B4",
  "liningParentGradBgtop": "#f6f6f6",
  "liningParentGradBgBtm": "#B4B4B4",
  "liningParentGradBgtopHover": "#FFF",
  "liningParentGradBgBtmHover": "#D5D5D5",
  "liningHrBg": "#000",
  "liningHrColor": "#000",
  "appearanceBoxShadow": "#888",
  "appearanceBoxGradBgTop": "#f6f6f6",
  "appearanceBoxGradBgBtm": "#E0E0E0",
  "oneilAppearWeightBg": "#B8DAFF",
  "tiMacdErrorBox": "#666",
  "tiMacdErrorBG": "#FEFBCE",
  "tiMacdErrorColor": "#BB0812",
  "buttonActiveState": "#04CCFE",
  "dg-symbolStackBg": "#000",
  "dg-rayChartContainer": "#30373F",
  "dg-symbolChartTitle": "#77CAFE",
  "dg-symbolChartTitleHover": "#FFF",
  "dg-symbolChartTitleGray": "#999",
  "dg-typeNScale": "#808080",
  "dg-typeNScaleHover": "#FFF",
  "dg-techIndicators": "#808080",
  "dg-legendListText": "#999",
  "dg-techratingBg": "#0E1216",
  "dg-techratingText": "#808080",
  "dg-logNLin": "#808080",
  "dg-logNLinHover": "#FFF",
  "dg-copyRightsTxt": "#666",
  "dg-copyRightsTxtHover": "#FFF",
  "dg-copyRightsBg": "rgba(30, 30, 32, 0.7)",
  "dg-copyRightsTxtyellow": "#111",
  "dg-copyRightsBgyellow": "rgba(0, 0, 2, 0.7)",
  "dg-pricesDate": "#FFF",
  "dg-ohPercentHead": "#808080",
  "dg-ohPercentHeadHover": "#FFF",
  "dg-ohPercentData": "#FFF",
  "dg-pivotPercentText": "#ff33cc",
  "dg-chartPanelLeftBg": "#000",
  "dg-chartMultiOptionMenuBtnTxt": "#FFF",
  "dg-chartMultipleTitle": "#000",
  "dg-chartMultipleTitleBorder": "#AEB0B3",
  "dg-chartCorporateEventBtn": "#FFF",
  "dg-intraDayBg": "#000",
  "dg-intraDayBorder": "#2D343B",
  "dg-intraDayTitleColor": "#999",
  "dg-intraDayVolume": "#FFF",
  "dg-timeLineBorder": "transparent",
  "dg-timelineLeftSpacer": "#000",
  "dg-legendListPopover": "#FFF",
  "dg-legendListPopoverDiff": "#000",
  "dg-emailPopupPlaceHolder": "#999",
  "dg-toolTipBg": "#FFFFB3",
  "dg-toolTipTexts": "#333",
  "dg-toolTipTextsStrock": "#666",
  "dg-financialValueDigit": "#FFF",
  "dg-financialMetricMesure": "#928e8e",
  "dg-financialMetricMesureValue": "#FFF",
  "dg-flyClustMenuBg": "#369",
  "dg-flyClustBtnStroke": "#4B82B7",
  "dg-flyClustBtnIconColor": "#FFF",
  "dg-flyClustBtnHoverBg": "#BDDEFF",
  "dg-flyClustBtnHoverColor": "#000",
  "dg-flyClustTitleColor": "#FFF",
  "dg-tiIndicatorSubMenuStrock": "#767B81",
  "dg-tiIndicatorDropDownBg": "#ECEFF3",
  "dg-tiIndicatorDropDownText": "#333",
  "dg-tiIndicatorDropDownArrow": "#333",
  "dg-selectFlyoutBorder": "#646973",
  "dg-tiIndicatorHoverBg": "#bddeff",
  "dg-tiIndicatordeactiveColor": "#777",
  "dg-flyClustToolTipText": "#000",
  "dg-flyClustSelectGroup": "#369",
  "dg-flyclustDragGroup": "#BDDEFF",
  "dg-sortable-drag": "#646973",
  "dg-flyClustStripBgOnHover": "#BDDEFF",
  "dg-flyClustStripBgOnDrag": "#BDDEFF",
  "dg-flyDragIconColor": "#FFF",
  "dg-flyDragIconDisableColor": "#CCC",
  "dg-estimateLengthColor": "#000",
  "dg-estimateLengthPeriodBg": "#DEDEDE",
  "dg-estimateLengthPeriodColor": "#333",
  "dg-estimateLengthPeriodCountBg": "#A4A4A4",
  "dg-estimateLengthPeriodEventBg": "#B8DAFF",
  "dg-estimateLengthPeriodEventBorder": "#FFF",
  "dg-appPerformanceBg": "#25242b",
  "dg-appPerformanceHeadBgTop": "rgba(35,35,35,.7)",
  "dg-appPerformanceHeadBg": "rgba(35,35,35,.5)",
  "dg-appPerformanceData": "#CCC",
  "dg-appPerformanceBorder": "#333",
  "dg-toolTipBlackWhiteBg": "rgb(0,0,0)",
  "dg-toolTipBlackWhiteBorder": "rgb(255,255,255)",
  "dg-toolTipYellowBg": "rgb(255, 255, 179)",
  "dg-toolTipYellowBorder": "rgb(102, 102, 102)",
  "dg-toolTipYellowTexts": "#555",
  "dg-eventBadgeScrollBg": "rgba(0,0,0,.2)",
  "dg-eventBadgeScrollBorder": "rgba(0,0,0,.7)",
  "dg-toolTipLightYellowBg": "#FEFBCE",
  "dg-toolTipLightYellowTextColor": "#000",
  "dg-pointerChangesBg": "#FFF",
  "dg-pointerChangesColor": "#262626",
  "dg-pointerNoteBg": "#F1F1F1",
  "dg-pointerNoteBorder": "#CCC",
  "dg-pointerNoteBorderTop": "#666",
  "dg-zigzagLine": "#FFFFFF",
  "dg-zigzagIndicatorTitle": "#FFFFFF",
  "dg-zigzagTooltipBg": "#FFFFFF",
  "dg-zigzagTooltipText": "#333",
  "dg-swingsizebg": "#767B81",
  "dg-swingsizeborder": "#A4A4A4",
  "dg-textDisabled": "#AAA",
  "epsAnimationText": "#000000",
  "calInputDataValue1": "#434343",
  "calInputDataValue2": "#313131",
  "calInputDataValue3": "#222",
  "calInputDataValue4": "#1e1e1e",
  "calInputDataValuehistoricalBg1": "#b6ad93",
  "calInputDataValuehistoricalBg2": "#9f9472",
  "calInputDataValuehistoricalBg3": "#82775d",
  "calInputDataValuehistoricalBg4": "#685f55",
  "shareMsgBg": "#afb3b6",
  "shareMsgBorder": "#767b81",
  "shareActionLink": "#000",
  "shareActionLinkHover": "#0066CC",
  "shareActionManageBtn": "#666",
  "priceLineFontColor": "#000",
  "priceLineHr": "#A0A0A0",
  "regionContryTop": "#FFF",
  "regionContryBtm": "#CFCFCF",
  "regionContryTopHover": "#E8F5FD",
  "regionContryBtmHover": "#BEE6FD",
  "regionContryTopSelected": "#C2E4F6",
  "regionContryBtmSelected": "#ABDAF3",
  "regionContryDisabled": "#F4F4F4",
  "regionContryDisabledBorder": "#ADB2B5",
  "regionContryBorder": "#707070",
  "regionContryBorderHover": "#3C7FB1",
  "regionContryBorderSelected": "#2C628B",
  "linePickerBgTop": "#F6F6F6",
  "linePickerBgBtm": "#B4B4B4",
  "linePickerBorder": "#000",
  "linePickerHr": "#000",
  "linePickerActive": "#B8DAFF",
  "autoFillInputBG": "#FFF",
  "autoFillInputBorder": "#AAA",
  "autoFillInputBGDisabled": "#F4F4F4",
  "autoFillInputBorderDisabled": "#ADB2B5",
  "supportText": "#777",
  "colorPickerBorder": "#666",
  "blackYellowRedBorder": "#000",
  "blackYellowRedBg": "#FEFBCE",
  "blackYellowRedColor": "#B00",
  "modalContentStroke": "#6B7076",
  "modalContentBackground": "#A6AAAD",
  "modalHeaderBackground": "#AFB3B6",
  "modalHeaderStrokeTop": "#DADCE0",
  "modalHeaderStrokeBtm": "#7F8790",
  "modalTitleColor": "#000",
  "modalCloseIcon": "-373px -398px",
  "modalBodyStrokeTop": "#DADCE0",
  "modalMaterialBackground": "#E8E8E8",
  "modalMaterialStroke": "#7F8790",
  "extSettingBorderColor": "#161719",
  "extSettingIconColor": "#999",
  "extRiPanelParaColor": "#FFF",
  "extRiPanelBackground": "#161619",
  "extRiPanelGridLines": "#4E4E4E",
  "extEditableText": "#018fee",
  "extEditableInputBG": "#FFF",
  "extEditableInputFont": "#333",
  "extUploadHeading": "#7abf8c",
  "extHelpTextColor": "#0CF",
  "extHelpTextColorHover": "#FFF",
  "extHelpTextColorModalHover": "#000",
  "extInstructionSeprator": "#6C7175",
  "extenalDataBg": "#475057",
  "extLoadSummaryTable": "#6C7175",
  "extLoadSummaryTableTitle": "#96E096",
  "extLoadSummaryTableBg": "#FFF",
  "extLoadSummaryTableBorder": "#CCC",
  "extLoadSummaryTableHeadColor": "#333",
  "extLoadSummaryTableHeadBg": "#E3FDA2",
  "extLoadSummaryTableBodyColor": "#333",
  "extLoadSummaryTableHeadBgChange": "#FFF",
  "extDragDropHolderBg": "rgba(255,255,255,.4)",
  "extDragDropHolderStrock": "#666",
  "extDragDropMsgStrock": "#777",
  "extDragDropMsgBg": "rgba(221,221,221,.5)",
  "extDragDropMsgText": "#666",
  "modalRegionBg": "#e8e8e8",
  "modalRegionBorder": "#7f8790",
  "extWizardBg": "rgba(71,80,87,.85)",
  "extWizardBackdrop": "rgba(0,0,0,.45)",
  "extWizardMsg": "#41464a",
  "extTipTopBorder": "#333",
  "extTipBtmBorder": "#000",
  "extTipBg": "#1a1b1d",
  "extTipTxt": "#6C6",
  "update-setting-heading": "#000",
  "extUpdateHelpTextColor": "#069",
  "extUpdateHelpTextColorHover": "#000",
  "extUpdateSeprator": "#d5d4d4",
  "extAdditionalsBorder": "#444",
  "extAdditionalsBg": "#1E1E20",
  "extAdditionalsHeading": "#EEE",
  "extAdditionalsLiHead": "#666",
  "extAdditionalsLiValue": "#CCC",
  "extAdditionalsCount": "#DDD",
  "extAdditionalsCountStroke": "#AAA",
  "extAdditionalsCountPara": "#BBB",
  "recurringFormBorder": "#CCC",
  "recurringFormBg": "#FFF",
  "recurringFormTitleColor": "#000",
  "recurringFormTitleBg": "#CCC",
  "recurringDropDownColor": "#3AA9FB",
  "popoverTimeSelectBorder": "#cccccc",
  "popoverTimeSelectBg": "#a6aaad",
  "eduWizardBg": "#000",
  "eduIdentifierBg": "#222",
  "eduGridListHolderBorder": "#000",
  "eduGridHeaderBorderColor": "#000",
  "eduGridHeaderTop": "#323338",
  "eduGridHeaderBtm": "#151619",
  "eduGridHeaderHoverTop": "#868891",
  "eduGridHeaderHoverBtm": "#44464C",
  "eduGridHeaderSorterTop": "#53545B",
  "eduGridHeaderSorterBtm": "#28292E",
  "eduGridHeaderCellWizardTop": "#aacd8d",
  "eduGridHeaderCellWizardBtm": "#8bb35a",
  "eduGridHeaderCellWizardHoverTop": "#c8e1b2",
  "eduGridHeaderCellWizardHoverBtm": "#9dc36e",
  "eduGridHeadCellFont": "#DDD",
  "eduGridHeadSortSelect": "#FFF",
  "eduGridBodyCellFont": "#A9A9A9",
  "eduGridBodySortSelect": "#DDD",
  "eduGridHeadCellFontShadow": "#000",
  "extTiIndicatorSettingBg": "#fff",
  "extTiIndicatorSettingColor": "#000",
  "extTiIndicatorSettingStroke": "#cfcfcf",
  "extTiIndicatorSettingThBg": "#e1e1e1",
  "extTiIndicatorDefineSetBorder": "#999",
  "extTiIndiAssignNoteFont": "#acacac",
  "extTiIndiAssignAnchorLink": "#333",
  "extTiIndiAdvanceSettingHead": "#000",
  "extTiIndiAdvanceSettingPara": "#666",
  "extTiIndicatorEventBadegeColor": "#666",
  "extTiIndicatorEventBadegeBorder": "#ddd",
  "extTiIndicatorEventBadegeBg": "#FFF",
  "extGridAnchoreLink": "#A9A9A9",
  "extGridAnchoreLinkHover": "#77cafe",
  "extGridAnchoreLinkRowHover": "#FFF",
  "extConfirmDateHeadColor": "#FFF",
  "extConfirmDateHeadShadow": "#FFF",
  "extConfirmDateIdentifireBg": "#ECECEC",
  "extConfirmDateIdentifireBorder": "#999",
  "extConfirmDateRecognizerBg": "#AFB3B6",
  "extConfirmDateRecognizerBorder": "#888",
  "extConfirmDateIdentifireLiBorder": "#FFF",
  "extConfirmDateIdentifireTxt": "#444",
  "extConfirmDateIdentifireLiHoverTop": "#0098cb",
  "extConfirmDateIdentifireLiHoverBtm": "#00739a",
  "extConfirmDateIdentifireHoverTxt": "#000",
  "extConfirmDateRecognizerColor": "#222",
  "modalMsgBg": "#475057",
  "modalMsgFooterDivider": "#72787e",
  "linkOfModal": "#6CF",
  "linkOfModalHover": "#FFF",
  "tabSwitchSelectedBorder": "#999",
  "tabSwitchSelectedBG": "#444",
  "outerDashLine": "#FFF",
  "targetDashLine": "#E8F2FD",
  "factorLinkColor": "#77cafe",
  "legendBackgroundColor": "#333",
  "factorButtonHoverBackgroundColor": "#5c6069",
  "finPanBg": "#ECECEC",
  "finBorder": "#8B929A",
  "finNavIndexBg": "#8B929A",
  "finNavIndexColor": "#FFF",
  "finNavIndexBgActive": "#000",
  "finTabContentBorder": "#8B929A",
  "finRestoreColor": "#000",
  "finRestoreColorDisable": "#555",
  "finCustomizeListStroke": "#666",
  "finCustomLargeListBg": "#BDDEBF",
  "finCustomLargeListBorder": "#666",
  "finCustomMediumListBg": "#CEF1CF",
  "finCustomSmallListBg": "#E9FEEA",
  "finListRotateColor": "#333",
  "finMetricCotentBorder": "#dbdbdb",
  "finMetricItemBgHover": "#89CFF0",
  "finTabListColor": "#666",
  "tabSameColorBorder": "#ECECEC",
  "finItemColor": "#000",
  "oneilBg": "#000",
  "oneilFocusBg": "#000",
  "oneilBtnBorderBottom": "#111",
  "oneilContactPanelBg": "#000",
  "oneilContactBorderTop": "#444",
  "oneilContactHoverBg": "#111",
  "oneilConNameColor": "#fff",
  "oneilConPhoneColor": "#fff",
  "oneilConDesignationColor": "#999",
  "oneilConMailColor": "#99CCFF",
  "oneilConMailColorHover": "#337ab7",
  "oneilConMailColorFocus": "#006699",
  "oneilSwichHeadColor": "#999",
  "oneilHistoricBg": "#28282F",
  "oneilHistoricHeadingBorder": "#39393F",
  "oneilNoActivityColor": "#AAA",
  "oneilWellBordercolor": "#444",
  "oneilWellBGHover": "#111",
  "oneilNameColor": "#99CCFF",
  "oneilNameColorHover": "#337ab7",
  "oneilNameColorFocus": "#006699",
  "oneilDateColor": "#999",
  "oneilAnalysisColor": "#99CCFF",
  "oneilAnalysisColorHover": "#337ab7",
  "oneilAnalysisColorFocus": "#006699",
  "oneilMoreBg": "#333",
  "oneilMoreBorderColor": "#222",
  "oneilMoreColor": "#99CCFF",
  "oneilMoreColorHover": "#337ab7",
  "oneilMoreColorFocus": "#006699",
  "oneilRIPanSwitchStrock": "#000",
  "miniListBoxBorderBtm": "#000",
  "miniListPlayerBorderTop": "#5C5C5C",
  "bgMLButtonTop": "#CDCDCD",
  "bgMLButtonBtm": "#BABABA",
  "bgMLButtonTopHover": "#757575",
  "bgMLButtonBtmHover": "#505050",
  "bgMLButtonBorder": "#3B3B3B",
  "datePickerCloseTop": "#CFCED3",
  "datePickerCloseBtm": "#919292",
  "datePickerCloseHover": "#B8DCFF",
  "datePickerDivider": "#484A52",
  "waiting": "-275px -500px",
  "addRows": "-175px -500px",
  "priceAlertDefault": "-150px -400px",
  "priceAlertHover": "-150px -425px",
  "priceAlertTrigger": "-175px -400px",
  "priceAlertTriggerHover": "-175px -425px",
  "listAlertDefault": "-200px -400px",
  "listAlertTrigger": "-200px -425px",
  "listAlertDefaultDisabled": "-225px -400px",
  "listAlertTriggerDisabled": "-225px -425px",
  "imageLightTheme": "-80px 0",
  "imageDarkTheme": "-230px 0",
  "imageNormalFilter": "0 -100px",
  "imageMetricFilter": "0 -30px",
  "imageAdditionalFilter": "0 -0",
  "imageAlertPanel": "-170px -100px",
  "imageAlertLinePen": "-170px -125px",
  "imageAlertTriggered": "-275px -125px",
  "radio": "/Asset/images/lightSprite.png",
  "radio2x": "/Asset/images/lightSprite2x.png",
  "annotationContentBg": "#3B3B3B",
  "annotationPaletteBg": "#5C5C5C",
  "annotateToolkitBg": "#3B3B3B",
  "annotateToolkitStroke": "#ABADB3",
  "annotateToolkitTitle": "#BDBDBD",
  "paletteListBg": "#3A4147",
  "paletteBorderTop": "#3B3B3B",
  "paletteBorderInternal": "#5C5C5C",
  "paletteLayerNameColor": "#FFF",
  "paletteLayerEditColor": "#000",
  "paletteLayerEditStroke": "#569DE5",
  "paletteLayerSelectColor": "#FFF",
  "paletteLayerSelectStroke": "#000",
  "paletteLayerSelectBgTop": "#4F4F50",
  "paletteLayerSelectBgBtm": "#2E3033",
  "paletteLayerSelectiveBg": "#3A4147",
  "annotateToolBg": "#1E1E1F",
  "annotateToolLinkHover": "#7E7E7E",
  "annotateToolLinkHoverBorder": "#000",
  "annotateToolLinkSelective": "#CFE4FC",
  "annotateToolLinkSelectiveBorder": "#5C5C5C",
  "annotateToolSeprator": "#5C5C5C",
  "annotateReportBg": "#1E1E1F",
  "annotateReportHead": "#999",
  "annotateReportData": "#FFF",
  "annotateReportLink": "#77CAFF",
  "annotateReportLinkHover": "#FFF",
  "footIconLiBorder": "#000",
  "annoFootIconBgTop": "#323337",
  "annoFootIconBgBtm": "#1E1F21",
  "annotateSelectBorder": "#5C5C5C",
  "paletteHeadingColor": "#FFF",
  "paletteTextColor": "#999",
  "paletteOpacitySliderBorder": "#808080",
  "paletteCheckboxTextColor": "#CCC",
  "AnntwoSiderBorder": "#000",
  "AnnColorPickerBg": "#D3D3D3",
  "AnnColorPickerBgInset": "#FFF",
  "AnnColorPickerStrokeInset": "#808080",
  "AnnWeightDropDownBg": "#ECEFF3",
  "AnnWeightStrokeBorder": "#000",
  "AnnWeightStrokeColor": "#000",
  "AnnWeightStrokeColorSelected": "#FFF",
  "annRangeSlidePointer": "-450px -375px",
  "annLayerSelectBgTop": "#505050",
  "annLayerSelectBgBtm": "#2D2F32",
  "annLayerSelectListColor": "#FFF",
  "annLayerSelectListActive": "#B8DAFF",
  "annLayerSelectListInactive": "#6f6f6f",
  "annLayerSelectListColorActive": "#000",
  "annLayerInfoBg": "#3B3B3B",
  "annLayerInfoTextColor": "#CCC",
  "annFontSelectListActive": "#D0E5FF",
  "annFontSelectDropDownStroke": "#000",
  "transparent": "transparent",
  "btnSequenceSelectiveBg": "#CDE3FB",
  "btnSequenceSelectiveColor": "#000",
  "annDropDownOptionSelective": "-375px -425px",
  "annMeasureTitleAndBorder": "#656565",
  "annMeasureTitleFontColor": "#FFF",
  "annMeasureStuffBg": "#202123",
  "annMeasureStuffHeadFontColor": "#777",
  "annMeasureCover": "rgba(0, 0, 0, 0.5)",
  "annoOverlape": "rgba(0, 0, 0, 0.4)",
  "qqGridSeparatorBg": "#2F3132",
  "qqGridSeparator": "#2F3132",
  "qqGridBg": "#000",
  "qqGridBorder": "#000",
  "qqGridStroke": "#2C2C2C",
  "qqGridFont": "#9DA4A5",
  "qqInternalBg": "#0E0E0E",
  "qqInternalBorder": "#3C3C3D",
  "quiQuoH5": "#CCC",
  "qqHeads": "#888",
  "qqValue": "#CCC",
  "qqStatisticsFirstChild": "#CCC",
  "qqStatisticsFont": "#9DA4A5",
  "qqInformationBg": "#101019",
  "qqInformationStroke": "#2E2E2E",
  "qqParaFontColor": "#CCC",
  "qqLink": "#77CAFE",
  "nupSvBorder": "#2E2E2E",
  "nupSvMenuBorder": "#777B81",
  "nupSvMenuBG": "#EFEFF8",
  "nupSvMenuLiFontColor": "#333",
  "nupSvMenuLiBorder": "#CCC",
  "nupSvMenuLiHoverBg": "#B8DAFF",
  "nupSvMenuLiHoverBorder": "#FFF",
  "nupSvMenuPeriodcityShadow": "#CCC",
  "nupFlagTop": "#999",
  "nupFlagTopHover": "#3AB9CE",
  "nupFlagBtmActive": "#008FAE",
  "nupFlagBtmActiveHover": "#3AB9CE",
  "bgNupViewGrid": "#000",
  "bgNupViewGridBoxBg": "#2F3132",
  "colHeadPeriodicity": "#999",
  "dropdownTextColor": "#444",
  "dropdownTextColorEvent": "#000",
  "dropdownBorderColor": "#FFF",
  "nupBoxFontColor": "#FFF",
  "nupBoxFontColorHover": "#77CAFE",
  "nupBoxParaColor": "#BBB",
  "nupBoxStrokeColor": "#262626",
  "nupVolTitleColor": "#808080",
  "nupBoxSelective": "#FFF",
  "nupBoxClicked": "#2C4258",
  "nupInfotableOddColor": "#999",
  "nupInfotableOddborder": "#464646",
  "nupInfotableEvenColor": "#CCC",
  "icnListStandard": "-450px -250px",
  "icnListMultiChart": "-450px -275px",
  "icnListMultiChartDD": "-450px -352px",
  "icnListAnalytics": "-450px -300px",
  "icnListQuoteBoard": "-450px -325px",
  "icnListHoverStandard": "-500px -250px",
  "icnListHoverMultiChart": "-500px -275px",
  "icnListHoverMultiChartDD": "-475px -352px",
  "icnListHoverAnalytics": "-500px -300px",
  "icnListHoverQuoteBoard": "-500px -325px",
  "icnListSelectiveStandard": "-550px -250px",
  "icnListSelectiveMultiChart": "-550px -275px",
  "icnListSelectiveMultiChartDD": "-475px -377px",
  "icnListSelectiveAnalytics": "-550px -300px",
  "icnListSelectiveQuoteBoard": "-550px -325px",
  "messageText": "#999",
  "switchBorderLightTheme": "#000"
};

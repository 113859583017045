import GraphType from "../../../Constants/GraphType";
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper";
import SettingsStore from "SettingsStore";
class PriceMenuHelper {
    getNavDataGraphSettings(){
        const consoleSettings = SettingsStore.getConsoleSettings();
        return consoleSettings.NavDatagraphSettings.NavDatagraphSettings;
    }
    getInstSettings() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        return DatagraphHelper.getSettingsObject(consoleSettings);
    }
    getPeriodicity() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        return tabDataGraphSettings.Periodicity;
    }
    getMajorPeriodicity() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        return PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
    }
    getNodeWidth() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        return tabDataGraphSettings.NodeWidth;
    }
    setHeight(height){
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDatagraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        tabDatagraphSettings.priceChartDimension.height = height;
    }
    setWidth(width){
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDatagraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        tabDatagraphSettings.priceChartDimension.width = width;
    }
    getHeight() {
        let height = 1;
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDatagraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        // if (tabDatagraphSettings.windowDimension.height !== 0) {
            height = tabDatagraphSettings.priceChartDimension.height;
        // }
        return height;
    }
    getWidth() {
        let width = 1;
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDatagraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        // if (tabDatagraphSettings.windowDimension.width !== 0) {
            width = tabDatagraphSettings.priceChartDimension.width;
        // }
        return width;
    }

    isZigzagIndicatorEnabled(hasZigzagBasicEntitlement, hasZigzagAIEntitlement, zigzagSettings, isIntraday, majorPeriodicity, isStockView, isIndexView, isETForCEF, isIndustryView, isLineChart) {

        if (!(hasZigzagBasicEntitlement || hasZigzagAIEntitlement || isLineChart)) {
            return false;
        }
    
        const isValidPeriodicityType = !(isIntraday || majorPeriodicity === GraphType.Quarterly ||
            majorPeriodicity === GraphType.Annual);
    
        const isValidViewType = isStockView ||
            isIndexView ||
            isETForCEF ||
            isIndustryView;
    
        const meetsAllConditions = isValidViewType &&
            isValidPeriodicityType &&
            zigzagSettings?.isVisible;
    
        return meetsAllConditions;
    }
}
const PriceMenuHelpers = new PriceMenuHelper();
export default PriceMenuHelpers;
import React, { useEffect, useState, useRef } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { REACTIONS } from '../../Constants/AINewsConstants';
import AddFeedbackIcon from './AddFeedbackIcon';

const AIReactionModal = ({ text, handleReaction, parentText }) => {
    const popoverRef = useRef(null);
    const reactionRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const selectedEmojiRef = useRef(selectedEmoji);
    const [comments, setComments] = useState("");
    const handleReactionClick = (emoji) => {
        setSelectedEmoji(emoji);
    }
    const EMOJI_SET = [
        { reaction: REACTIONS.LIKE, fontSize: '18px', className: 'like-emoji' },
        { reaction: REACTIONS.DISLIKE, fontSize: '18px', className: 'dislike-emoji' },
        { reaction: REACTIONS.UNSURE, fontSize: '16px', className: 'unsure-emoji' }
    ]

    useEffect(() => {
        selectedEmojiRef.current = selectedEmoji;
    }, [selectedEmoji]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const popoverElement = ReactDOM.findDOMNode(popoverRef.current);
            if (
                popoverElement &&
                !popoverElement.contains(event.target) && showPopup
            ) {
                if(selectedEmojiRef.current) {
                    handleReaction(text, selectedEmojiRef.current.reaction, comments, parentText);
                }
                setShowPopup(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showPopup, comments]);

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleReaction(text, selectedEmoji.reaction, comments, parentText)
          setShowPopup(false)
        }
    };

    return (
        <>
            {
                (selectedEmoji && !showPopup) ? <div
                    className={`selected-emoji ${selectedEmoji.className}`}
                    style={{ fontSize: selectedEmoji.fontSize }}
                >
                </div> :
                    <>
                        <span ref={reactionRef} className={`emoji ${showPopup ? "active" : ""}`}>
                            <AddFeedbackIcon
                                isClicked={showPopup}
                                onClick={() => {
                                    setShowPopup(true);
                                }}
                            />
                        </span>
                    </>
            }
            <Overlay
                show={showPopup}
                placement="top"
                target={reactionRef.current}
            >
                <Popover id="reaction-popover" ref={popoverRef} className='reaction-popover'>
                    {
                        EMOJI_SET.map((emoji, index) => {
                            return <div
                                className={`emoji-container ${selectedEmoji?.reaction === emoji.reaction ? "active" : ""}`}
                                key={index}
                            >
                                <div
                                    className={`${emoji.className} emoji`}
                                    onClick={() => handleReactionClick(emoji)}>
                                </div>
                            </div>
                        })
                    }
                    <input
                        type="text"
                        disabled={!selectedEmoji}
                        placeholder="Enter feedback(optional)"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        onKeyDown={handleKeyPress}
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            cursor: !selectedEmoji ? "not-allowed" : "text",
                        }}
                    />
                </Popover>
            </Overlay>
        </>
    )
};

export default AIReactionModal;

import React from "react";
import ReactDOM from "react-dom";
import NavType from "NavType";
// import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Modal } from "react-bootstrap";
import CompareTabType,{ CompareMenuType } from '../../Constants/CompareTabType';
import store from '../../Redux/store'
import { Button, OverlayTrigger } from "react-bootstrap";
import SettingsAction from "../../Actions/SettingsActions";
import NavDataGraphBottomBar from "Components/NavModules/NavDataGraph/BottomBar/BottomBar.jsx";
import NavListBottomBar from "Components/NavModules/NavList/BottomBar/BottomBar.jsx";
import NavResearchBottomBar from "Components/NavModules/NavResearch/BottomBar/BottomBar.jsx";
import NavCompareBottomBar from "Components/NavModules/NavCompare/Bottombar/BottomBar.jsx";
import NavNewsBottomBar from "Components/NavModules/NavNews/BottomBar/BottomBar.jsx";
import ConsoleStore from "../../Stores/ConsoleWindow/ConsoleStore";
import LocalizationStore from 'LocalizationStore';
import ThemeSetting from "../../RayCustomControls/ThemeSetting/ThemeSetting.jsx";
import QuoteStatusPopover from "../../RayCustomControls/PopOver/QuoteStatusPopOver.jsx";
import DdeStatusType from "../../Constants/DdeStatusType.js";
import WebSyncUtil from "../../Utils/WebSyncUtil.js";
import ServerInfoUtil from "../../Utils/ServerInfoUtil.js";
import RealTimeQuoteType from "../../Constants/RealTimeQuoteType.js";
import WorkSpaceRealTimePrices from "../../Utils/RealTimeHelper/WorkSpaceRealTimePrices.js";
import ConsoleActions from "Actions/ConsoleActions";
import AlertActions from 'AlertActions';
import BaseServiceApi from "BaseServiceApi";
import BrowserUtil from "../../Utils/BrowserUtil";
import AlertStore from 'AlertStore';
import { AlertConstants } from 'AlertConstants';
import MessageCenterWindowType from "../../Constants/MessageCenterWindowType";
import NotificationWindow from "../../RayCustomControls/Alerts/NotificationWindow.jsx";
import MessageCenterDialog from "../../RayCustomControls/MessageCenter/MessageCenterDialog.jsx";
import TabDataGraphActionClass from "Actions/TabDataGraphAction.js";
//import Calender from '../../RayCustomControls/Calender/Calender.jsx';
import SharedDialog from "../../RayCustomControls/Share/ShareDialog.jsx";
import ShareStore from "../../Stores/Share/ShareStore.js";
import DatagraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import UserInfoUtil from "UserInfoUtil";
// import PopUpBlockerAlert from "../../RayCustomControls/Print/PopUpBlockerAlert.jsx";
import EventCalendar from "../../RayCustomControls/CalenderControl/EventCalendar";
import moment from "moment";
//import StringUtil from 'StringUtil';
import ThemeHelper from "ThemeHelper";
import ErrorBoundary from 'ErrorBoundary';
import TabType from "Constants/TabType.js";
var EntitlementType = BaseServiceApi.rayData["EntitlementType"];
import EmailRequest from "../../RayCustomControls/Print/EmailRequest.jsx";
import ShareControlMenu from "./ShareControlMenu";
//import { TabDataGraphConstants } from "../../Constants/TabDataGraphConstants";
import PortalDialog from "../../Components/Dialogs/PortalDialog";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "../../Constants/SettingsConstants.js";
import TimeTrackingWindow from "TimeTrackingWindow";
import { PrintMode } from "../../print/printmode.js";
import MiniListStore from "MiniListStore";
import { dispatch } from "../../Redux/dispatch.js";
//import { getExternalDataSubMenu } from "../../Actions/ExternalDataUploaderActions";
//import PortalContainer from 'PortalContainer';
import { updateNupStore, resetNupState } from "../../Actions/NupViewActions";
import ListMenu from "../../Components/NavModules/NavList/Nup/ListMenu";
import CurrencyButton from "../../RayCustomControls/Currency/CurrencyButton";
import { onPlotSymbol, updateAnalyzerUrl } from "../../Actions/DatagraphActions";
import { stopDatagraphStreaming } from "../../Actions/PricePanelActions";

var IntradaySourceType = BaseServiceApi.rayData.IntradaySourceType;

let selectQuoteServiceOption = 0;
let _prevUrl = null;
const idleTimeoutMinutesValue = 20;
const totalSecondsInMinute = 60;
const secondToMilliSecondConversionValue = 1000;
const idleTimeOutValue = idleTimeoutMinutesValue*totalSecondsInMinute*secondToMilliSecondConversionValue;
let timeoutID;
let debounceTimeout;
let countDownInterval;

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.SettingsStateChange = this.SettingsStateChange.bind(this);
    this.AlertStoreStateChange = this.AlertStoreStateChange.bind(this);
    this.openMessageCenter = this.openMessageCenter.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = ConsoleStore.getStates();
    this.showCalender = false;
    this.shareStoreStateChange = this.shareStoreStateChange.bind(this);
    this.DatagraphStoreStateChange = this.DatagraphStoreStateChange.bind(this);
    this.startPrint = this.startPrint.bind(this);
    this.hidePopUpAlert = this.hidePopUpAlert.bind(this);
    this.toggleMailRequestDialog = this.toggleMailRequestDialog.bind(this);
    this.submitEmailRequest = this.submitEmailRequest.bind(this);
    this.closeWindowPortal = this.closeWindowPortal.bind(this);
    this.onPopUpBlockerActive = this.onPopUpBlockerActive.bind(this);
    this.hideContextMenu = this.hideContextMenu.bind(this);
    this.showContextMenu = this.showContextMenu.bind(this);
    this.openSupportDialog = this.openSupportDialog.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.openChart = this.openChart.bind(this);
    this.fullscreen = false;
    this.state = { ...this.state, isShowPopUpMenu: false, style: {}, displayTimeoutPopup: false, countDownSeconds: 60 };
    this.IsUserEntitledAutoSignOut = UserInfoUtil.IsUserEntitled(EntitlementType.PANARAY_AUTO_SIGNOUT_Entitlement);
    this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
  }

/******Idle timeout methods start */

//Used for registring mouse and keyboard event listeners
  registerIdleTimeoutEvents = () => {
    document.addEventListener("mousemove", this.resetTimer, false);
    document.addEventListener("mousedown", this.resetTimer, false);
    document.addEventListener("keypress", this.resetTimer, false);
    document.addEventListener("keydown", this.resetTimer, false);
    document.addEventListener("DOMMouseScroll", this.resetTimer, false);
    document.addEventListener("mousewheel", this.resetTimer, false);
    document.addEventListener("touchmove", this.resetTimer, false);
    document.addEventListener("MSPointerMove", this.resetTimer, false);
    this.startTimer();
  }
  
  //Used for removing mouse and keyboard event listeners
    removeIdleTimeoutEvents = () => {
    document.removeEventListener("mousemove", this.resetTimer, false);
    document.removeEventListener("mousedown", this.resetTimer, false);
    document.removeEventListener("keypress", this.resetTimer, false);
    document.removeEventListener("keydown", this.resetTimer, false);
    document.removeEventListener("DOMMouseScroll", this.resetTimer, false);
    document.removeEventListener("mousewheel", this.resetTimer, false);
    document.removeEventListener("touchmove", this.resetTimer, false);
    document.removeEventListener("MSPointerMove", this.resetTimer, false);
  }
  
  //Starts timer after user dont interacts with ui
    startTimer = () => {
    timeoutID = window.setTimeout(this.goInactive, idleTimeOutValue);
  }
  
  //resets the timer after every interaction of user with ui
   resetTimer = (e) => {
    window.clearTimeout(timeoutID);
    if(debounceTimeout)
    window.clearTimeout(debounceTimeout);
    debounceTimeout = window.setTimeout(this.goActive,1000);
  }
  onDocumentKeyPress(e) {
    let charCode = (e.which) ? e.which : e.keyCode;

    /* Ctrl + p print for symbol & compare view*/
    if (e.ctrlKey && charCode === 80) {
      if ((this.state.ActiveNav === NavType.NavSymbol && this.state.SelectedTabKey === TabType.Datagraph) || this.state.ActiveNav === NavType.NavCompare) {
        this.startPrint();
      }
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if(e.srcElement.tagName !== 'INPUT' && e.srcElement.tagName !== 'TEXTAREA' && charCode === 32 && !e.srcElement.isContentEditable){
      e.preventDefault();
    }
  }
  //Method Either forcefully logouts out or displays popup for asking user about session activity
  logOutTimer = (forceLogout = false) => {
    if (forceLogout) {
      if (countDownInterval)
        window.clearInterval(countDownInterval);
        this.props.history.push(`/Logout`);
    }
    else {
      let countDownSecondsValue = 60;
      this.setState({countDownSeconds:countDownSecondsValue});
      countDownSecondsValue--;

      countDownInterval = window.setInterval(()=>{
        if(countDownSecondsValue === -1){
          window.clearInterval(countDownInterval);
          ConsoleStore.setSessionExpiredPopupStatus(true);
          this.props.history.push(`/Logout`);
          this.setState({ displayTimeoutPopup: false});
        }
        this.setState({countDownSeconds:countDownSecondsValue});
        countDownSecondsValue--;
      },1000);
    }
  }

  //cancels the logout when user wishes to stay some more time
  cancelLogout = () =>{
    window.clearInterval(countDownInterval);
    this.startTimer();
    document.body.backgroundColor = "";
    this.setState({displayTimeoutPopup:false})
  }

//Executes after user remain idle for some minutes
  goInactive = () => {
    try {
        const isMiniListPlaying = MiniListStore.getMiniListPlayingStatus();
        const benchmarkState = store.getState().rotationGraph.BenchMarkReducer;
        const benchmarkSliderPlayingStatus = ConsoleStore.getStates().ActiveNav === NavType.NavCompare ? 
        (benchmarkState.selectedMenuTab === CompareMenuType.Symbols ? benchmarkState.SymbolsData.isPlaying : benchmarkState.GroupsData.isPlaying) : false;
        if (!isMiniListPlaying && !benchmarkSliderPlayingStatus) {
        this.setState({ displayTimeoutPopup: true })
         this.logOutTimer();
        }
    } catch (err) {
     this.setState({ displayTimeoutPopup: true })
     this.logOutTimer();
    }
  }
  
  //Executes for every activity of user
   goActive = () => {
    if (!this.state.displayTimeoutPopup) {
      this.startTimer();
    }
  }

  //    captureNetworkRequest() {
//     var capture_network_request = [];
//     var capture_resource = performance.getEntriesByType("resource");
//     capture_resource = capture_resource.filter((resource) => resource.initiatorType ==="xmlhttprequest");
//     for (var i = 0; i < capture_resource.length; i++) {
//         if (capture_resource[i].initiatorType == "xmlhttprequest" ) {
//           console.log(capture_resource[i].name);
//             if (capture_resource[i].name.indexOf('www.demo.com OR YOUR URL') > -1) {
//                 capture_network_request.push(capture_resource[i].name)
//             }
//         }
//     }
//     return capture_network_request;
// }

/******Idle timeout methods end */

  handleChange(e) {
    let node = ReactDOM.findDOMNode(e.target),
      link = node.getAttribute("data-link"),
      path = "";
      const consoleStoreState = ConsoleStore.getStates();

    consoleStoreState.onSwitchToSymbolTab = false;
    if (link === NavType.NavSymbol) {
      
      if (consoleStoreState.firstLoadedTab === NavType.NavSymbol) {
        consoleStoreState.isTabChanged = true;

        // fetching latest data if any new file was uploaded
        // dispatch(getExternalDataSubMenu(ConsoleStore.getSettings(), true));
        const storeState = DatagraphStore.getState();
        consoleStoreState.onSwitchToSymbolTab = true;
        dispatch(onPlotSymbol(storeState.Symbol, true, 500, false, false, false, true));
      }
      else {
        consoleStoreState.isTabChanged = true;
        consoleStoreState.firstLoadedTab = NavType.NavSymbol;
      }

      path = NavType.NavSymbol;
    }
    else if (link === NavType.NavLists) {
      path = NavType.NavLists;
    }
    else if (link === NavType.NavCompare) {
      path = NavType.NavCompare;
    }
    else if (link === NavType.NavResearch) {
      if (this.state.IsResearchEnabled) {
        path = NavType.NavResearch;
      } else {
        return false;
      }
    }
    else if (link === NavType.NavNews) {
      if (this.state.IsNewsEnabled) {
        path = NavType.NavNews;
      } else {
        return false;
      }
    }
    else if (link === NavType.NavAccAnalyzer) {
      if (this.state.isAccountAnalyzerEnabled) {
        path = NavType.NavAccAnalyzer;
      } else {
        return false;
      }
    }

    if (path === NavType.NavSymbol || path === NavType.NavCompare) {
      // TimeTrackingWindow.resetTimeTracker();
    }
    if(consoleStoreState.ActiveNav === NavType.NavLists){
      dispatch(resetNupState());
    }
    if(consoleStoreState.ActiveNav === NavType.NavSymbol){
      dispatch(stopDatagraphStreaming());
    }
    consoleStoreState.isDGApiCompleted = false;
    localStorage.setItem("_activeNav", path);
    SettingsAction.setUserSettings({ ActiveNav: path, Name: this.myTick.state.Name, Desc: this.myTick.state.Desc, Status: this.myTick.state.Status, BrushColor: this.state.BrushColor, QuoteStatusGrid: this.myTick.state.QuoteStatusGrid });

    //log usage
    this.logUsage(link);
  }

  componentDidMount() {
    ConsoleStore.addChangeListener(this.SettingsStateChange);
    //SettingsStore.addChangeListener(this.SettingsStateChange);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.REMOVE_NOTIFICATION);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
    ShareStore.addChangeListener(this.shareStoreStateChange);
    DatagraphStore.addChangeListener(this.DatagraphStoreStateChange);
    AlertActions.getAllNotifications();
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
    window.addEventListener('message', this.handleMessage);

    //Session timeout feature will only be enabled for Users having Entitlement
    if (this.IsUserEntitledAutoSignOut)
      this.registerIdleTimeoutEvents();
    dispatch(updateNupStore())
  }

  componentWillUnmount() {
    ConsoleStore.removeChangeListener(this.SettingsStateChange);
    //SettingsStore.removeChangeListener(this.SettingsStateChange);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.REMOVE_NOTIFICATION);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
    ShareStore.removeChangeListener(this.shareStoreStateChange);
    DatagraphStore.removeChangeListener(this.DatagraphStoreStateChange);
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    window.removeEventListener('message', this.handleMessage);

    //Session timeout feature will only be enabled for External Users
    if(!this.isInHouseUser)
    this.removeIdleTimeoutEvents();
  }

  componentDidUpdate() {
    if (BrowserUtil.getBrowserName() == "Safari") {
      setStylesForMac();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location && nextProps.location !== this.props.location) {
      let path = nextProps.location.pathname;
      if (path && /^\/Symbol(\/{1})\w*/i.test(path)) {
        path = NavType.NavSymbol;
        const state = DatagraphStore.getState();
        if (nextProps.location.pathname !== this.props.location.pathname && state.Symbol !== (nextProps.location.pathname).substring(8)) {
          const symbol = ConsoleStore.getSymbolFromPath(nextProps.location.pathname)
          //delay 0.5 sec
          window.setTimeout(() => {
            const nextstate = DatagraphStore.getState();
            const currentPath = this.props.location.pathname;
            const states = MiniListStore.getState();
            if (!states.IsMiniListPlay &&currentPath && /^\/Symbol(\/{1})\w*/i.test(currentPath) && nextstate.Symbol !== (this.props.location.pathname).substring(8)) {
              dispatch(onPlotSymbol(symbol, true, 500, false, false, false, true));
            }
          }, 500)
        }
      }
      else {
        path = path.replace('/', '');
      }
      let state = ConsoleStore.getStates();
      let uperCasePath = path.toUpperCase();
      let activePath = '';
      switch (uperCasePath) {
        case 'LISTS': activePath = 'Lists';
          break;
        case 'SYMBOL': activePath = 'Symbol';
          break;
        case 'RESEARCH': activePath = 'Research';
          break;
        case 'COMPARE': activePath = 'Compare';
          break;
        case 'NEWS': activePath = 'News';
          break;
        case 'ACCOUNT-ANALYZER': activePath = 'Account-Analyzer';
          break;
        default: break;
      }     
      if (state.ActiveNav != activePath && (activePath == 'Lists' || activePath == 'Symbol' || activePath == 'Research' || activePath == 'Compare' || activePath == 'News')) {
        localStorage.setItem("_activeNav", activePath);
        SettingsAction.setUserSettings({ ActiveNav: activePath, Name: this.myTick.state.Name, Desc: this.myTick.state.Desc, Status: this.myTick.state.Status, BrushColor: this.state.BrushColor, QuoteStatusGrid: this.myTick.state.QuoteStatusGrid });
      }
      if (state.printServerUrl) {
        if (_prevUrl != state.printServerUrl) {
          this.printPdfFile(state.printServerUrl);
        }
      }
    }
  }

  handleMessage = (event) => {
    if (event.data.action === 'NAVIGATE_TO_SYMBOL_VIEW') {
      dispatch(onPlotSymbol(event.data.symbol));
      ConsoleStore.plotSymbol(event.data.symbol);
    }
    else if (event.data.action === 'UPDATE_AA_URL') {
      dispatch(updateAnalyzerUrl(event.data.url))
    }
  }

  verifyTabInUrl(activeNav) {
    const loc = this.props.location;
    if (loc.pathname && /^\/Symbol(\/{1})\w*/i.test(loc.pathname)) {
     const pathArray = loc.pathname.split('/');
     if(pathArray[1].toUpperCase() === activeNav.toUpperCase()) {
       return false;
     }
    }
    return true;
  }

  verifySymbolInUrl(symbol) {
    const loc = this.props.location;
    if (loc.pathname && /^\/Symbol(\/{1})\w*/i.test(loc.pathname)) {
     const pathArray = loc.pathname.split('/');
     if(pathArray[2].toUpperCase() === symbol.toUpperCase()) {
       return false;
     }
    }
    return true;
  }

  SettingsStateChange() {
    let state = ConsoleStore.getStates();
    const ConsoleStoreAction = ConsoleStore.getCurrentAction();

    if (ConsoleStoreAction === SettingsConstants.ActionTypes.HANDLE_PRINT_ERROR_PAGE) {
      this.setState(state);
    }
    // if (ConsoleStoreAction === ConsoleConstants.ActionTypes.SHOW_PRINT_POPUP) {
        // this.setState({ showPrintStatus: true });
    // }
    this.setState(state);
    const currentAction = SettingsStore.getCurrentAction();

    if (state.ActiveNav === NavType.NavSymbol && (currentAction === SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT || currentAction === SettingsConstants.ActionTypes.SET_USER_SETTINGS)) {
      let settings = ConsoleStore.getSettings();
      let symbol = settings.NavDatagraphSettings.symbol;

      if (symbol && this.verifySymbolInUrl(symbol)) {
        if(PrintMode.printing) {
          this.props.history.replace(`/${ConsoleStore.getSymbolUrl(symbol)}?print=true&workspaceId=${PrintMode.workspaceId}`);
        }
        else {
          this.props.history.replace(`/${ConsoleStore.getSymbolUrl(symbol)}`);
        }
      }
      SettingsStore.clearCurrentAction();
    }
    else if (currentAction === SettingsConstants.ActionTypes.SET_USER_SETTINGS) {
      if (this.verifyTabInUrl(state.ActiveNav))
        this.props.history.replace("/" + state.ActiveNav);
      SettingsStore.clearCurrentAction();
    }
    

    if (state.printServerUrl) {
      if (_prevUrl != state.printServerUrl) {
        this.printPdfFile(state.printServerUrl);
      }
    }
  }

  AlertStoreStateChange() {
    let notifications = AlertStore.getMessageCenterNotifications(MessageCenterWindowType.All);

    if (this.state.unreadMessageCount != notifications.unreadCount)
      this.setState({ unreadMessageCount: notifications.unreadCount });
  }

  shareStoreStateChange() {
    const sharedState = ShareStore.getState();
    this.setState({
      sharedDataState: sharedState
    });
  }

  DatagraphStoreStateChange() {
    const state = DatagraphStore.getState();
    const currentAction = state.action;
    if (currentAction === SettingsConstants.ActionTypes.GET_RT_PRICE_DATA || currentAction === "UPDATE_FLAG"){
      return;
    }
    if (state.SymbolInfo) {
      this.setState({
        targetCurrencyCode: state.SymbolInfo.DefaultCurrency ? state.SymbolInfo.DefaultCurrency : null,
        finTargetCurrencyCode: state.finTargetCurrencyCode,
        isCurrencyConversion: state.isCurrencyConversion,
        SelectedTabKey: state.SelectedTabKey,
        SymTypeEnum: state.SymbolInfo.SymTypeEnum
      });
    }
  }

  getBottomBar() {
    let bottomBar = null;
    let ActiveNav = this.state.ActiveNav;
    switch (ActiveNav) {
      case NavType.NavSymbol:
        bottomBar = <NavDataGraphBottomBar />;
        break;
      case NavType.NavLists:
        bottomBar = <NavListBottomBar />;
        break;
      case NavType.NavCompare:
        bottomBar = <NavCompareBottomBar />;
        break;
      case NavType.NavResearch:
        bottomBar = <NavResearchBottomBar />;
        break;
      case NavType.NavNews:
        bottomBar = <NavNewsBottomBar />;
        break;
      default:
        break;
    }
    return bottomBar;
  }

  openSupportDialog() {
    ConsoleActions.toggleSupportDialog(true);
  }
  hideMenu() {
      this.fullscreen = true;
      const state = ConsoleStore.getStates();
      this.setState(state);
  }
  openChart() {
      this.fullscreen = true;
      const state = ConsoleStore.getStates();
      this.setState(state, () => {
          TabDataGraphActionClass.processJpeg();
      });
  }
  openMessageCenter() {
    AlertActions.openMessageCenter();
    this.setState({ messageCenterOpened: this.state.messageCenterOpened == undefined ? true : !this.state.messageCenterOpened });
  }

  showPrintProgress() {
    return this.state.showPrintStatus && this.state.PrintJobs && this.state.PrintJobs.length > 0;
  }

  closeWindowPortal() { 
    // ConsoleActions.closePrintStatus(false);
  }

  onPopUpBlockerActive() {
    ConsoleActions.togglePopUpBlockerAlert(true);
  }

  startPrint() {
    console.log("In the footer startPrint")
    if (!this.state.showPrintStatus && this.state.PrintJobs.length > 0) {
      this.setState({ showPrintStatus: true });
      return;
    }

    const settings = ConsoleStore.getSettings();
    const symbol = settings.NavDatagraphSettings.symbol;
    const ActiveNav = settings.ActiveNav;
    const activeTab = settings.NavCompareSettings.SelectedTabKey;
    const isCompareTab = activeTab === CompareTabType.ComparisonGraph;
    const periodicity = settings.NavDatagraphSettings.TabDataGraphSettings.Periodicity;
    const documentName = ActiveNav === NavType.NavSymbol ? `${symbol} - ${periodicity}` : ActiveNav === NavType.NavCompare && isCompareTab ? `Comparison Charts` : `Rotation Graph`;
    const printType = ActiveNav === NavType.NavSymbol ? 'SINGLE' : isCompareTab ? 'COMPARISON' : 'ROTATIONAL';
    const state = DatagraphStore.getState();
    const existingJob = this.state.PrintJobs.find((job) => job.Symbol == documentName);
    if (existingJob && (existingJob.Status == 0 || existingJob.Status == 1)) {
      this.setState({ showPrintStatus: false });
      this.hideContextMenu();
      setTimeout(()=>{ this.setState({ showPrintStatus: true }); },500);
      return;
    }
    console.log("Starting print");
    ConsoleActions.startPrint([symbol], documentName, state.SymbolType, '', printType, periodicity);
    this.hideContextMenu();
  }

  toggleMailRequestDialog() {
    this.hideContextMenu();
    this.setState({ showEmailRequestDialog: !this.state.showEmailRequestDialog })
  }

  logUsage(tabtype) {
    let logDescription = null;
    switch (tabtype) {
      case NavType.NavSymbol:
        logDescription = 'NavSymbolSelected';
        break;
      case NavType.NavLists:
        logDescription = 'NavListSelected';
        break;
      case NavType.NavCompare:
        logDescription = 'ComparisionModeSelected';
        break;
      case NavType.NavResearch:
        logDescription = 'NavResearchSelected';
        break;
      case NavType.NavNews:
        logDescription = 'NavNewsSelected';
        break;
      default:
        break;
    }

    //Log Usage
    ConsoleStore.trackUsage(logDescription, '', []);
  }

  dayPicker() {
    this.showCalender = true;
    let state = ConsoleStore.getStates();
    this.setState(state);
  }

  hidePopUpAlert() {
    ConsoleActions.togglePopUpBlockerAlert(false);
  }

  submitEmailRequest() {
    this.toggleMailRequestDialog();
  }

  hideContextMenu() {
    this.setState({ isShowPopUpMenu: false });
  }

  showContextMenu() {
    // if(DatagraphStore.getIsCryptoCurrencySymbol()) return;  /* For crypto currency symbols */
    let style = {};
    if (this.shareBtn) {
      const popUp = this.shareBtn.getBoundingClientRect();
      style = { left: popUp.left, bottom: "40px" };
    }
    this.setState({ isShowPopUpMenu: true, style: style });
  }

  printPdfFile(url, isSecondCall) {

    if (isSecondCall)
      return;
    _prevUrl = url;
    let openWindow = window.open(url, '_blank');
    if (!openWindow) {
      let isIE = false || !!document.documentMode;
      if (!isIE) {
        ConsoleStore.togglePopUpAlert(true);
        let self = this;
        setTimeout(() => self.printPdfFile(url, isIE), 3000);
      }
    }
    if (openWindow) {
      openWindow.opener = null;
    }
  }

  handleFullScreen(videoMode) {
      if (!videoMode) return false;
      const fullScreen = (window.innerHeight === screen.height) || window.navigator.standalone || this.fullscreen;
      TabDataGraphActionClass.onFullScreen(fullScreen);
      return fullScreen;
  }

  handleMouseDown(e) {
      this.fullscreen = false;
      const state = ConsoleStore.getStates();
      state.showFooter = false;
      this.setState(state);
  }

  render() {
    let NavSymbol = "", NavLists = "", NavResearch = "", NavCompare = "", NavNews = "", NavAccAnalyzer = "";
    const sharedDataState = this.state.sharedDataState ? this.state.sharedDataState : undefined;
    const selectedDataGraphTab = DatagraphStore.getState().SelectedTabKey;
    // const iconStatus = DatagraphStore.getIsCryptoCurrencySymbol() ? { cursor: "not-allowed" } : {} /* For crypto currency symbols */
    const iconStatus = {};
    if (this.state) {
      let ActiveNav = this.state.ActiveNav;
      if (ActiveNav) {
        NavSymbol = (ActiveNav == NavType.NavSymbol) ? "active" : "";
        NavLists = (ActiveNav == NavType.NavLists) ? "active" : "";
        NavCompare = (ActiveNav == NavType.NavCompare) ? "active" : "";
        NavResearch = (ActiveNav == NavType.NavResearch) ? "active" : "";
        NavNews = (ActiveNav == NavType.NavNews) ? "active" : "";
        NavAccAnalyzer = (ActiveNav == NavType.NavAccAnalyzer) ? "active" : ""
      }
    }
    let bottomBar = this.getBottomBar();
    let icnClassName = `${this.state.messageCenterOpened ? 'opened' : ''} badge1`;
    const isDisplayPrintIcon = this.state.ActiveNav && ((this.state.ActiveNav === NavType.NavSymbol && this.state.SelectedTabKey === TabType.Datagraph) || (this.state.ActiveNav === NavType.NavCompare) || (this.state.ActiveNav === NavType.NavCompare));
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const showListMenu = this.state.ActiveNav === NavType.NavLists;
    let videoMode = tabDataGraphSettings.videoMode;
    let fullScreen = this.handleFullScreen(videoMode);
    //let backgroundColor = ThemeHelper.getThemedBrush("000fff");
    const isCurrencyControlVisible = DatagraphStore.isCurrencyControlVisible();
    const isHistoric = ConsoleStore.getStates().isHistoric;

    if (fullScreen && !PrintMode.printing) {
      var copyColor = ThemeHelper.getThemedBrush("fff000");
      let quoteMessage1 = "Charts powered by Nasdaq Last Sale";
      return (<div style={{
        position: "absolute",
        height: 'Auto',
        width: 'Auto',
        font: 'calibri',
        fontSize: '18px',
        fontWeight: 'Bold',
        background: "Transparent",
        opacity: '1',
        borderColor: "Transparent",
        borderStyle: "solid",
        borderWidth: "1px",
        left: '45px',
        color: copyColor,
        zIndex: '10'
      }}>

        <div className="copyRightSymbol"
          onMouseDown={this.handleMouseDown}
          style={{
            fontSize: '40px',
            cursor: "pointer",
            marginTop: (window.innerHeight - 60) + 'px'
          }}>
          <div>{quoteMessage1.toUpperCase()}
          </div>
        </div>
      </div>
      );
    }
    else if (!PrintMode.printing) {
      return (
        <footer>
          {/*  style={{ background: backgroundColor }} */}
          <div className="footer-right-part">
            {bottomBar}
            {NavSymbol === "active" && selectedDataGraphTab !== TabType.Ownership &&  selectedDataGraphTab !== TabType.Holdings &&
              <ErrorBoundary>
                <EventCalendar />
              </ErrorBoundary>
            }
            {videoMode === true && <Button bsSize="small" onClick={this.hideMenu}>{"Hide Menu"}</Button>}
            {videoMode === true && <Button bsSize="small" onClick={this.openChart}>{"Chart"}</Button>}
          </div>
          <div className="footer-left-part">
            <div className="global-dropdown" id="start-menu-setting">
              <ThemeSetting />
            </div>

            {videoMode === false &&
              <nav className="segment-nav">
                <ul className="right-skew">
                  <li className={NavSymbol}>
                    <a data-link={NavType.NavSymbol} onClick={this.handleChange}>
                      <span className="segment-icon icn-symbol" data-link={NavType.NavSymbol}></span>
                      <span className="segment-text" data-link={NavType.NavSymbol}>{LocalizationStore.getTranslatedData(
                        'main_symbol',
                        'Symbol')}</span>
                    </a>
                  </li>
                  { /* <li className={"icn-list " + NavLists}> */
                  }
                  <li className={NavLists}>
                    <a data-link={NavType.NavLists} onClick={this.handleChange}>
                      <span className="segment-icon icn-list" data-link={NavType.NavLists}></span>
                      <span className="segment-text" data-link={NavType.NavLists}>{LocalizationStore.getTranslatedData(
                        'main_lists',
                        'Lists')}</span>
                    </a>
                  </li>
                  { /* <li className={"icn-compare " + NavCompare}> */
                  }
                  <li className={NavCompare}>
                    <a data-link={NavType.NavCompare} onClick={this.handleChange}>
                      <span className="segment-icon icn-compare" data-link={NavType.NavCompare}></span>
                      <span className="segment-text" data-link={NavType.NavCompare}>{LocalizationStore.getTranslatedData(
                        'main_compare',
                        'Compare')}</span>
                    </a>
                  </li>
                  { /* <li className={this.state.IsResearchEnabled ? "icn-group " + NavResearch : "icn-group " + NavResearch + " disable"}> */
                  }
                  <li className={this.state.IsResearchEnabled ? NavResearch : NavResearch + " disable"}>
                    {this.state.IsResearchEnabled
                      ? <a data-link={NavType.NavResearch} onClick={this.handleChange}>
                        <span className="segment-icon icn-group" data-link={NavType.NavResearch}></span>
                        <span className="segment-text" data-link={NavType.NavResearch}>{
                          LocalizationStore.getTranslatedData('main_research', 'Research')}</span>
                      </a>
                      : <a>
                        <span className="segment-icon icn-group" data-link={NavType.NavResearch}></span>
                        <span className="segment-text" data-link={NavType.NavResearch}>{
                          LocalizationStore.getTranslatedData('main_research', 'Research')}</span>
                      </a>
                    }
                  </li>
                  { /* <li className={this.state.IsNewsEnabled ? "icn-news " + NavNews : "icn-news " + NavNews + " disable"}> */
                  }
                  {/* <li className={this.state.IsNewsEnabled ? NavNews : NavNews + " disable"}>
                    {this.state.IsNewsEnabled
                      ? <a data-link={NavType.NavNews} onClick={this.handleChange}>
                        <span className="segment-icon icn-news" data-link={NavType.NavNews}></span>
                        <span className="segment-text" data-link={NavType.NavNews}>{LocalizationStore.getTranslatedData(
                          'main_news',
                          'News')}</span>
                      </a>
                      : <a>
                        <span className="segment-icon icn-news" data-link={NavType.NavNews}></span>
                        <span className="segment-text" data-link={NavType.NavNews}>{LocalizationStore.getTranslatedData(
                          'main_news',
                          'News')}</span>
                      </a>}
                  </li> */}
                  <li className={this.state.isAccountAnalyzerEnabled ? NavAccAnalyzer : NavAccAnalyzer + "disable"}>
                    <a data-link={NavType.NavAccAnalyzer} onClick={this.handleChange}>
                      <span className="segment-icon analyzer" data-link={NavType.NavAccAnalyzer}>
                        <span className="preload-image_bw"></span>
                        <span className="preload-image_color"></span>
                      </span>
                      <span className="segment-text" data-link={NavType.NavAccAnalyzer}>Analyzer</span>
                    </a>
                  </li>
                </ul>
              </nav>}
            <div className="additional-nav">
              {videoMode === false &&
                <Tick ref={(ref) => this.myTick = ref} />}
              {videoMode === false &&
                <Button bsSize="small" onClick={this.openSupportDialog}>{
                  LocalizationStore.getTranslatedData(
                    "contact",
                    'Contact')}</Button>
              }
              {videoMode === false && isDisplayPrintIcon &&
                <Button bsSize="small" className="badge1" style={iconStatus} onClick={this.showContextMenu}>
                  <i ref={(ref) => this.shareBtn = ref} className="icn-print-n-email" />
                </Button>
              }
              {videoMode === false &&
                this.state.isShowPopUpMenu &&
                <ShareControlMenu style={this.state.style} startPrint={this.startPrint
                } toggleMailRequestDialog={
                  this.toggleMailRequestDialog} hideContextMenu={this.hideContextMenu} />}
              {videoMode === false &&
                <Button bsSize="small" className={icnClassName} data-badge={this.state.unreadMessageCount >
                  0
                  ? this.state.unreadMessageCount
                  : null} onClick={this.openMessageCenter}><span className="icn-bullhorn" /></Button>
              }
              {this.state.targetCurrencyCode && isCurrencyControlVisible && !isHistoric &&
                <CurrencyButton />
              }
              <NotificationWindow />
              <MessageCenterDialog />
            </div>
            {showListMenu && <ListMenu />
            }
          </div>
          {
            sharedDataState &&
            sharedDataState.showSharedDialog &&
            <SharedDialog
              sharedData={sharedDataState.sharedData}
              managedData={sharedDataState.managedData}
              targetData={sharedDataState.targetData}
              dialogType={sharedDataState.dialog_Type}
              sharedAction={sharedDataState.sharedAction}
              userList={sharedDataState.userList}
              showUsers={sharedDataState.showUsers}
              nameFilter={sharedDataState.nameFilter}
              savedSharedData={sharedDataState.savedSharedData}
              showConfirmation={sharedDataState.showConfirmation}
              showInProgress={sharedDataState.showInProgress} />
          }
          <span id="measure" />
          {this.showPrintProgress() &&
          (
             <ErrorBoundary>
              <PortalDialog title={LocalizationStore.getTranslatedData("tp_title_status", "Print Status")
              } closeWindowPortal={this.closeWindowPortal} onPopUpBlockerActive={this.onPopUpBlockerActive}
                PrintJobs={this.state.PrintJobs} isPrintError={this.state.isPrintError} printErrorMsg={this.state.printErrorMsg}>
              </PortalDialog>
         </ErrorBoundary>
          )}
          {/* {
          this.state.showPopUpBlockerAlert &&
          <PopUpBlockerAlert open={this.state.showPopUpBlockerAlert} hidePopUpAlert={this.hidePopUpAlert} >
          </PopUpBlockerAlert>
        }  */}
          {
            this.state.showEmailRequestDialog &&
            <EmailRequest open={this.state.showEmailRequestDialog} hideEmailDialog={this
              .toggleMailRequestDialog}>
            </EmailRequest>
          }
          {this.state.displayTimeoutPopup ? (<Modal className="modal-popup session-timeout" show={this.state.displayTimeoutPopup} backdrop="static">
            <Modal.Header><h4>Session Timeout</h4></Modal.Header>
            <Modal.Body>
              <div className="modal-material">
                <p>You have been inactive for a while. For your security, we'll log you out in {this.state.countDownSeconds} seconds. Would you like to continue using the system?</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <span className="btn-box center">
                <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.cancelLogout}>Continue</button>
                <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={() => this.logOutTimer(true)}>Logout</button>
              </span>
            </Modal.Footer>
          </Modal>) : ""}
        </footer>
      );
    }
    else {
      return null;
    }
  }
}

class Tick extends React.Component {
  constructor(props) {
    super(props);
    this.tick = this.tick.bind(this);
    this.state = ConsoleStore.getStates();
    this.changeStatusBrush = this.changeStatusBrush.bind(this);
    this.SettingsStateChange = this.SettingsStateChange.bind(this);
  }

  componentDidMount() {
    ConsoleStore.addChangeListener(this.SettingsStateChange);
    this.timer = setInterval(this.tick, 5000);
  }
  componentWillUnmount() {
    ConsoleStore.removeChangeListener(this.SettingsStateChange);
    clearInterval(this.timer);
  }

  SettingsStateChange() {
    let state = ConsoleStore.getStates();
    this.setState(state);
    //if (this.myPopover && !this.myPopover.state.show) {
    // if (state.IsPop) {
    // this.myPopover.show();
    //}
    // }
  }

  changeStatusBrush(e) {
    let title = e.childNodes[1].innerHTML;
    let startIndex = title.indexOf(":") + 1;
    let startTitle = title.slice(0, startIndex);
    let statusTitle = title.slice(startIndex + 1);
    let finalTitle = "";
    let userOk = true;
    if (UserInfoUtil.getUserInfo()) {
      const entitlements = UserInfoUtil.getUserInfo().entitlements;
      if (!WebSyncUtil.IsConnected) {
        userOk = entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement);
      }
    }
    let textColor = userOk ? "<span className='red-brush'>" : "<span className='green-brush'>";
    let textContent = userOk
      ? LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED)
      : LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED);

    if (statusTitle === LocalizationStore.getTranslatedData('sc_st', DdeStatusType.STREAMING)) {
      finalTitle = startTitle.concat("<span className='green-brush'>", LocalizationStore.getTranslatedData('sc_st', DdeStatusType.STREAMING), "</span>");
    }
    else if (statusTitle === LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED)) {
      finalTitle = startTitle.concat("<span className='green-brush'>", LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED), "</span>");
    }
    else {
      finalTitle = startTitle.concat(textColor, textContent, "</span>");
    }
    if (title) {
      e.childNodes[1].innerHTML = finalTitle;
    }
  }

  tick() {
    let ddeStatus = LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
    let serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
    let userOk = true;
    if (UserInfoUtil.getUserInfo()) {
      const entitlements = UserInfoUtil.getUserInfo().entitlements;
      if (!WebSyncUtil.IsConnected) {
        userOk = entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement);
      }
    }
    let redColor = userOk ? "icn-red-dot" : "icn-green-dot";
    let dotColor = userOk ? "icn-red-dot" : "icn-green-dot";
    if (this.state.SelectQuoteServiceOption) {
      if (selectQuoteServiceOption === this.state.SelectQuoteServiceOption) {
        selectQuoteServiceOption = this.state.SelectQuoteServiceOption;
        if (this.state.SelectQuoteServiceOption === IntradaySourceType.IntradaySource_NASDAQ && WebSyncUtil.IsConnected) {
          if (WorkSpaceRealTimePrices.IsNasdaqMarketHours()) {
            ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('sc_st', DdeStatusType.STREAMING) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
            serverInfo = new ServerInfoUtil(RealTimeQuoteType.Nasdaq);
            dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
            //console.log("in market hours");
          } else {
            ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
            serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
            dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
            //console.log("not in market hours");
          }
          this.setState({
            Name: serverInfo.Name,
            Desc: serverInfo.Desc,
            Status: ddeStatus,
            QuoteStatusGrid: dotColor
          });
        }
        if (this.state.SelectQuoteServiceOption === IntradaySourceType.IntradaySource_Default && WebSyncUtil.IsConnected) {
          ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
          serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
          dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
          this.setState({
            Name: serverInfo.Name,
            Desc: serverInfo.Desc,
            Status: ddeStatus,
            QuoteStatusGrid: dotColor
          });
        }
      } else {
        selectQuoteServiceOption = this.state.SelectQuoteServiceOption;
        console.log("tick:" + this.state.SelectQuoteServiceOption);
        switch (this.state.SelectQuoteServiceOption) {
          case IntradaySourceType.IntradaySource_NASDAQ:
            if (WorkSpaceRealTimePrices.IsNasdaqMarketHours()) {
              ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('sc_st', DdeStatusType.STREAMING) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
              serverInfo = new ServerInfoUtil(RealTimeQuoteType.Nasdaq);
              dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
            }
            else {
              ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
              serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
              dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
            }
            break;
          case IntradaySourceType.IntradaySource_Default:
            ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
            serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
            dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
            break;
          default:
            break;
        }
        this.setState({
          Name: serverInfo.Name,
          Desc: serverInfo.Desc,
          Status: ddeStatus,
          QuoteStatusGrid: dotColor
        });
      }
    } else {
      ddeStatus = WebSyncUtil.IsConnected ? LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED) : LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED);
      serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
      dotColor = WebSyncUtil.IsConnected ? "icn-green-dot" : redColor;
      this.setState({
        Name: serverInfo.Name,
        Desc: serverInfo.Desc,
        Status: ddeStatus,
        QuoteStatusGrid: dotColor
      });
    }
    if (this.myPopover && this.myPopover.state.show) {
      this.myPopover.hide();
    }
  }

  render() {
    const popoverFocus = (
      <QuoteStatusPopover id="tooltip-trigger-focus" className="medium-normal" title={this.state.Name + ": " + this.state.Status}>
        {this.state.Desc}
      </QuoteStatusPopover>
    );
    return (
      <div className="connection-status">
        <span className="connection-port">
          <OverlayTrigger placement="right" overlay={popoverFocus} ref={(oLay) => this.myPopover = oLay} onEntering={(e) => this.changeStatusBrush(e)} >
            {this.state.QuoteStatusGrid ? <span className={"connection-switch " + this.state.QuoteStatusGrid}></span> : ""}
          </OverlayTrigger>
        </span>
      </div>
    )
  }
}
